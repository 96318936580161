export const fromWeek = (week?: string): Date => {
  if (!week) {
    return new Date();
  }

  const parts = week.split('-');

  return new Date(
    parseInt(parts[0], 10),
    parseInt(parts[1], 10) - 1,
    parseInt(parts[2], 10),
  );
};
