import { useCallback } from 'react';
import { deepClone } from '../../lib/deepClone';
import { useConfirmModal } from '../../ui/confirm';
import { DayOfWeek, Menu } from '../types';
import { useCreateMenu } from './useCreateMenu';
import { useUpdateMenu } from './useUpdateMenu';

export const useConfirmRemoveSide = (menu: Menu, day: DayOfWeek) => {
  const updateMenu = useUpdateMenu();
  const createMenu = useCreateMenu();
  const confirmModal = useConfirmModal();

  const handleRemoveSide = useCallback(
    (removeId: string) => {
      const newMenu = deepClone<Menu>(menu);

      const sideIds = newMenu.days[day].sideIds;
      newMenu.days[day].sideIds = sideIds?.filter(
        (sideId) => sideId !== removeId,
      );

      if ('id' in menu) {
        updateMenu(newMenu);
      } else {
        createMenu(newMenu);
      }
    },
    [createMenu, day, menu, updateMenu],
  );

  const handleConfirmRemoveSide = useCallback(
    async (removeId: string) => {
      try {
        const option = await confirmModal('Remove this side?', 'No', 'Yes');
        if (option === 'Yes') {
          handleRemoveSide(removeId);
        }
      } catch (err) {
        console.error(err);
      }
    },
    [confirmModal, handleRemoveSide],
  );

  return handleConfirmRemoveSide;
};
