import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getRedirectResult,
  signInWithRedirect,
  GoogleAuthProvider,
} from 'firebase/auth';
import { getAuth } from '../../firebase';
import { useGetInviteByToken } from '../api';
import Button from '../../form/button';
import LoadingSpinner from '../../ui/loadingSpinner';
import { useAuth } from '../../auth';

const provider = new GoogleAuthProvider();

const InviteAcceptPage = () => {
  const { inviteToken } = useParams();
  const navigate = useNavigate();
  const auth = useAuth();

  const invite = useGetInviteByToken(inviteToken!);

  const firebaseAuth = getAuth();
  const [loading, setLoading] = useState(true);

  const handleSignIn = () => {
    signInWithRedirect(firebaseAuth, provider);
  };

  useEffect(() => {
    getRedirectResult(firebaseAuth)
      .then((result) => {
        if (!result) {
          return;
        }

        auth
          .acceptInvite(result.user.uid, inviteToken as string)
          .then(() => navigate('/'));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  }, [auth, firebaseAuth, inviteToken, navigate]);

  if (!invite || loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="flex w-screen h-screen items-center justify-center flex-col">
      <div className="text-center my-8">
        <h2 className="font-bold text-lg my-2">
          You've been summoned to join Menu Magic!
        </h2>
      </div>

      <Button onClick={handleSignIn}>Accept</Button>
    </div>
  );
};

export default InviteAcceptPage;
