type ButtonOnClickHandler = () => void;

type ButtonPropsType = {
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  onClick: ButtonOnClickHandler;
  use?: string;
};

const USE_COLOR: { [key: string]: string } = {
  primary: 'bg-ron text-white',
  secondary: 'bg-ron-light border border-ron',
  destructive: 'bg-white text-draco border border-draco',
};

const Button = ({
  children,
  className = '',
  disabled = false,
  onClick,
  use = 'primary',
}: ButtonPropsType) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${className} ${USE_COLOR[use]} px-6 py-1 rounded`}
    >
      {children}
    </button>
  );
};

export default Button;
