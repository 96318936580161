import { useCallback } from 'react';
import CircleClose from '../../icons/circleClose';
import { Side } from '../../sides/types';
import Card from '../../ui/card';

export type SideCardProps = {
  side: Side;
  onRemoveSide: (id: Side['id']) => void;
};

const SideCard = ({ side, onRemoveSide }: SideCardProps) => {
  const { id } = side;
  const handleRemoveSide = useCallback(() => {
    onRemoveSide(id);
  }, [id, onRemoveSide]);

  return (
    <Card key={side.id}>
      <div className="relative">
        <button
          className="absolute right-[-8px] top-[-10px] w-button-sm h-button-sm"
          onClick={handleRemoveSide}
        >
          <CircleClose />
        </button>
        <div className="flex flex-col">
          <div className="flex flex-row">
            <div className="flex flex-col">
              <div>{side.name}</div>
            </div>
          </div>
          {side.url && (
            <div className="mt-4 pt-2 border-t border-slate-200">
              <a
                href={`../../recipes/?url=${encodeURIComponent(side.url)}`}
                className="text-harry-dark"
              >
                {side.url}
              </a>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default SideCard;
