type ConfirmPropsType = { className?: string; color?: string };

const Confirm = ({
  className = 'stroke-[3px] fill-transparent',
  color = 'stroke-slate-600',
}: ConfirmPropsType) => (
  <svg viewBox="0 0 50 50">
    <path className={`${className} ${color}`} d="M 7,25 l 12,12 l 23,-25" />
  </svg>
);

export default Confirm;
