import { useCallback, useEffect, useRef, useState } from 'react';

export const useDebounce = (cb: Function, wait: number = 1000) => {
  const [timeoutId, setTimeoutId] = useState<number>();
  const cbRef = useRef(cb);

  useEffect(() => {
    cbRef.current = cb;
  }, [cb]);

  const callback = useCallback(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    setTimeoutId(window.setTimeout(() => cbRef.current(), wait));
  }, [timeoutId, wait]);

  return callback;
};
