import { Routes, Route, Navigate } from 'react-router-dom';
import { startOfWeek } from '../lib/startOfWeek';
import { toWeek } from '../lib/toWeek';
import Week from './week';
import Day from './day';
import { restoreWeek } from '../lib/lastWeek';

const MenusPage = () => {
  const fallback = restoreWeek() || toWeek(startOfWeek());

  return (
    <Routes>
      <Route path=":week" element={<Week />} />
      <Route path=":week/:day" element={<Day />} />
      <Route index element={<Navigate to={fallback} />} />
    </Routes>
  );
};

export default MenusPage;
