type PillPropsType = {
  onClick: React.MouseEventHandler;
  value: boolean;
};

const Pill = ({
  children,
  onClick,
  value,
}: React.PropsWithChildren<PillPropsType>) => {
  return (
    <button
      onClick={onClick}
      className={`${
        value ? 'bg-harry' : 'bg-harry-light'
      } rounded-full m-1 px-4 py-2`}
    >
      {children}
    </button>
  );
};

export default Pill;
