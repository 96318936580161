type TodayPropsType = { className?: string };

const Today = ({ className = 'stroke-[3px] stroke-black' }: TodayPropsType) => (
  <svg viewBox="0 0 512 512">
    <rect
      className={className}
      fill="none"
      strokeLinejoin="round"
      x="48"
      y="80"
      width="416"
      height="384"
      rx="48"
    />
    <line
      className={className}
      fill="none"
      strokeLinejoin="round"
      strokeLinecap="round"
      x1="128"
      y1="48"
      x2="128"
      y2="80"
    />
    <line
      className={className}
      fill="none"
      strokeLinejoin="round"
      strokeLinecap="round"
      x1="384"
      y1="48"
      x2="384"
      y2="80"
    />
    <rect
      className={className}
      fill="none"
      strokeLinejoin="round"
      strokeLinecap="round"
      x="112"
      y="224"
      width="96"
      height="96"
      rx="13"
    />
    <line
      className={className}
      fill="none"
      strokeLinejoin="round"
      strokeLinecap="round"
      x1="464"
      y1="160"
      x2="48"
      y2="160"
    />
  </svg>
);

export default Today;
