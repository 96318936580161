import { Routes, Route } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import NotificationProvider from './notifications/NotificationProvider';
import ConfirmModalProvider from './ui/confirm';
import NotificationCenter from './notifications/NotificationCenter';
import { AuthProvider } from './auth';
import MealsPage from './meals';
import MenusPage from './menus';
import RecipesPage from './recipes';
import LabelsPage from './labels';
import SidesPage from './sides';
import LoginPage from './login';
import InvitePage from './invite';
import ServiceWorkerUI from './notifications/ServiceWorkerUI';
import GlobalErrorHandler from './errors/components/GlobalErrorHandler';
import RequiredAuth from './auth/components/RequiredAuth';
import LookupFamily from './auth/components/LookupFamily';
import ErrorPage from './errors/components/ErrorPage';

const App = () => {
  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <NotificationProvider>
        <GlobalErrorHandler />
        <ServiceWorkerUI />
        <AuthProvider>
          <ConfirmModalProvider>
            <NotificationCenter />
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/invite/*" element={<InvitePage />} />
              <Route path="/menu/:familyId" element={<RequiredAuth />}>
                <Route path="meals/*" element={<MealsPage />} />
                <Route path="menus/*" element={<MenusPage />} />
                <Route path="sides/*" element={<SidesPage />} />
                <Route path="labels/*" element={<LabelsPage />} />
                <Route path="recipes/*" element={<RecipesPage />} />
              </Route>
              <Route path="*" element={<LookupFamily />} />
            </Routes>
          </ConfirmModalProvider>
        </AuthProvider>
      </NotificationProvider>
    </ErrorBoundary>
  );
};

export default App;
