import FloatingButton from './floatingButton';
import Plus from '../icons/plus';

type FloatingAddButtonPropsType = {
  onClick: Function;
};

const FloatingAddButton = ({ onClick }: FloatingAddButtonPropsType) => (
  <FloatingButton onClick={onClick}>
    <Plus className="stroke-white stroke-[3px]" />
  </FloatingButton>
);

export default FloatingAddButton;
