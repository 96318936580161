import { useNavigate } from 'react-router-dom';
import Label from '../icons/label';
import Menu from '../icons/menu';
import Meal from '../icons/meal';
import Side from '../icons/side';
import { useMagicMode } from './MagicModeProvider';

const EmptySpot = () => <div className="w-[40px]" />;

export type NavButtonProps = {
  icon: React.ReactNode;
  isMagicMode: boolean;
  label: string;
  navId: string;
  onClick: Function;
};

const NavButton = ({
  icon,
  isMagicMode,
  label,
  navId,
  onClick,
}: NavButtonProps) => (
  <button
    onClick={() => onClick(navId)}
    className="flex flex-col lg:flex-row items-center lg:w-full lg:pt-4 lg:pb-4 lg:hover:bg-hermione-medium"
  >
    <div className="w-button h-button p-1">{icon}</div>
    <div className={`${isMagicMode ? 'text-slate-200' : 'text-white'} text-sm`}>
      {label}
    </div>
  </button>
);

const BottomBarNav = () => {
  const { isMagicMode } = useMagicMode();
  const navigate = useNavigate();

  const handleNav = (navId: string) => {
    navigate(navId);
  };

  return (
    <div
      className={`flex lg:flex-col lg:justify-start justify-around fixed lg:absolute bottom-0 lg:h-[calc(100%-70px) lg:top-[52px] left-0 right-0 lg:right-auto w-screen lg:w-[250px] bg-hermione border-t border-white lg:border-0 pb-2 lg:pb-0 pt-2 lg:pt-0 ${
        isMagicMode ? 'pointer-events-none bg-hermione-light' : ''
      }`}
    >
      <NavButton
        icon={
          <Menu
            className={`stroke-[3px] ${
              isMagicMode
                ? 'stroke-slate-200 fill-slate-200'
                : 'stroke-white fill-white'
            }`}
          />
        }
        isMagicMode={isMagicMode}
        navId="menus"
        label="Menus"
        onClick={handleNav}
      />
      <NavButton
        label="Meals"
        icon={
          <Meal
            className={`stroke-[3px] ${
              isMagicMode
                ? 'stroke-slate-200 fill-slate-200'
                : 'stroke-white fill-white'
            }`}
          />
        }
        isMagicMode={isMagicMode}
        navId="meals"
        onClick={handleNav}
      />
      <EmptySpot />
      <NavButton
        icon={
          <Side
            className={`stroke-[3px] ${
              isMagicMode
                ? 'stroke-slate-200 fill-slate-200'
                : 'stroke-white fill-white'
            }`}
          />
        }
        isMagicMode={isMagicMode}
        navId="sides"
        label="Sides"
        onClick={handleNav}
      />
      <NavButton
        icon={
          <Label
            className={`stroke-[3px] ${
              isMagicMode
                ? 'stroke-slate-200 fill-slate-200'
                : 'stroke-white fill-white'
            }`}
          />
        }
        isMagicMode={isMagicMode}
        navId="labels"
        label="Labels"
        onClick={handleNav}
      />
    </div>
  );
};

export default BottomBarNav;
