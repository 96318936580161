export const TAG = 'daily-meal-reminder';

export const unregisterBackgroundSync = async () => {
  const registration = await navigator.serviceWorker.ready;

  try {
    const status = await navigator.permissions.query({
      // @ts-expect-error experimental
      name: 'periodic-background-sync',
    });

    if (status.state === 'granted' && 'periodicSync' in registration) {
      // @ts-expect-error experimental
      await registration.periodicSync.unregister(TAG);
    }
  } catch (err) {
    console.error('Daily meal reminder could not be unregistered!');
    console.error(err);
  }
};
