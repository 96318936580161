import {
  addDoc,
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { getDb } from '../firebase';
import { FAMILY_VANITY_ID, getAndInc } from './globalIds';

export const familyDb = (familyId: string) =>
  doc(getDb(), 'families', familyId);
const familiesDb = () => collection(getDb(), 'families');
const membersDb = () => collectionGroup(getDb(), 'members');

type FamilyType = {
  id: string;
  vanityId: number;
};

export const getFamilyForUser = async (uid?: string): Promise<FamilyType> => {
  if (!uid) {
    throw new Error('No uid provided');
  }

  const querySnapshot = await getDocs(
    query(membersDb(), where('uid', '==', uid)),
  );

  if (querySnapshot.empty) {
    throw new Error('User not found');
  }

  const [docSnapshot] = querySnapshot.docs;

  const familyId = docSnapshot.ref?.parent?.parent?.id;

  if (!familyId) {
    throw new Error('Family Id not found');
  }

  const familySnapshot = await getDoc(doc(familiesDb(), familyId));

  if (!familySnapshot.exists()) {
    throw new Error('Family not found');
  }

  return { id: familySnapshot.id, ...familySnapshot.data() } as FamilyType;
};

export const addFamilyMember = async (
  familyId: string,
  uid: string,
  inviteToken: string,
): Promise<void> => {
  const familyRef = doc(familiesDb(), familyId, 'members', uid);

  await setDoc(familyRef, { uid, inviteId: inviteToken });
};

export const updatePushToken = async (
  familyId: string,
  uid: string,
  pushToken: string,
): Promise<void> => {
  const pushTokenRef = doc(
    familiesDb(),
    familyId,
    'members',
    uid,
    'pushTokens',
    pushToken,
  );
  const pushTokenDoc = await getDoc(pushTokenRef);

  if (!pushTokenDoc.exists()) {
    await setDoc(pushTokenRef, {
      token: pushToken,
      updatedAt: Timestamp.now(),
      userAgent: navigator.userAgent,
    });
  } else {
    await updateDoc(pushTokenRef, {
      updatedAt: Timestamp.now(),
      userAgent: navigator.userAgent,
    });
  }
};

export const createFamily = async (uid: string) => {
  const vanityId = await getAndInc(FAMILY_VANITY_ID);
  const familyRef = await addDoc(familiesDb(), { referrerId: uid, vanityId });

  return familyRef.id;
};
