import { addDoc } from 'firebase/firestore';
import { MenuEgg } from '../types';
import { db } from './db';

// TODO: runTransaction make sure not creating dupe week
export const createMenu = async (
  menu: MenuEgg,
  uid: string,
  familyId: string,
) => {
  try {
    return addDoc(db(familyId), { ...menu, uid }).then(({ id }) => ({
      id,
      ...menu,
    }));
  } catch (e) {
    console.error('Error adding document: ', e);
  }

  throw new Error('Could not create document');
};
