import { useCallback } from 'react';
import CircleClose from '../../icons/circleClose';
import { Meal } from '../../meals/types';
import { generateMealHash } from '../../meals/utils/generateMealHash';
import Card from '../../ui/card';
import ImageGenerator from '../../ui/imageGenerator';
import { RatingSection } from '../ratingSection';

export type MealCardProps = {
  meal: Meal;
  onRemoveMeal: (mealId: Meal['id']) => void;
};

const MealCard = ({ meal, onRemoveMeal }: MealCardProps) => {
  const { id } = meal;

  const handleRemoveMeal = useCallback(() => {
    onRemoveMeal(id);
  }, [id, onRemoveMeal]);

  return (
    <Card key={meal.id}>
      <div className="relative">
        <button
          className="absolute right-[-8px] top-[-10px] w-button-sm h-button-sm"
          onClick={handleRemoveMeal}
        >
          <CircleClose />
        </button>
        <div className="flex flex-col">
          <div className="flex flex-row">
            <ImageGenerator data={generateMealHash(meal)} />
            <div className="flex flex-col min-w-0 w-2/3">
              <div className="break-words">{meal.name}</div>
              <div className="text-slate-400">{meal.labels?.join(', ')}</div>
            </div>
          </div>
          {meal.url && (
            <div className="mt-4 pt-2 border-t border-slate-200 truncate">
              <a
                href={`../../recipes/?url=${encodeURIComponent(meal.url)}`}
                className="text-harry-dark"
              >
                {meal.url}
              </a>
            </div>
          )}
          <RatingSection mealId={meal.id} />
        </div>
      </div>
    </Card>
  );
};

export default MealCard;
