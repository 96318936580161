import ProfileMenu from '../profileMenu';

type ListHeaderPropsType = {
  children: React.ReactNode;
};

const ListHeader = ({ children }: ListHeaderPropsType) => (
  <header className="drop-shadow bg-hermione-medium text-white px-4 py-3 flex justify-between font-bold text-lg">
    <div>{children}</div>
    <ProfileMenu />
  </header>
);
export default ListHeader;
