export type CardPropsType = {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

const Card = ({
  children,
  className = '',
  onClick,
}: React.PropsWithChildren<CardPropsType>) => (
  <div
    className={`${className} drop-shadow bg-white m-2 p-4 first:mt-0`}
    onClick={onClick}
  >
    {children}
  </div>
);

export default Card;
