import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../useAuth';
import { getFamilyForUser } from '../families';
import LoadingSpinner from '../../ui/loadingSpinner';

const RedirectToFamilyUrl = ({
  app,
  path,
}: {
  app?: string;
  path?: string;
}) => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  // Need to lookup the vanity url
  useEffect(() => {
    let mounted = true;

    if (!auth.uid) {
      return;
    }

    const pathParts = location.pathname.split('/');
    const appPart = app || pathParts.slice(0, 2).join('/');
    const pathPart = path || pathParts.slice(3).join('/');
    const searchPart = location.search ? `?${location.search}` : '';
    const hashPart = location.hash ? `#${location.hash}` : '';

    getFamilyForUser(auth.uid).then((family) => {
      if (!mounted) {
        return;
      }

      navigate(
        `${appPart}/${family.vanityId}/${pathPart}${searchPart}${hashPart}`,
      );
    });

    return () => {
      mounted = false;
    };
  }, [app, auth, location, navigate, path]);

  return <LoadingSpinner />;
};

export default RedirectToFamilyUrl;
