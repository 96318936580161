import { deleteDoc, doc } from 'firebase/firestore';
import { db } from './db';

export const deleteMeal = async (familyId: string, id: string) => {
  try {
    return deleteDoc(doc(db(familyId), id));
  } catch (e) {
    console.error('Error deleting document: ', e);
  }

  throw new Error('Could not delete document');
};
