import { useCallback, useEffect, useState } from 'react';

type TextBoxOnChangeHandler = (value: string) => void;
type TextBoxOnSubmitHandler = () => void;

type TextBoxPropsType = {
  autofocus?: boolean;
  defaultValue?: string;
  label: string;
  onChange: TextBoxOnChangeHandler;
  onSubmit?: TextBoxOnSubmitHandler;
  placeholder?: string;
  value?: string;
};

const TextBox = ({
  autofocus,
  defaultValue,
  label,
  onChange,
  onSubmit,
  placeholder,
  value = '',
}: TextBoxPropsType) => {
  const handleChange = useCallback(
    ({ target: { value: newValue } }: React.ChangeEvent<HTMLInputElement>) => {
      onChange(newValue);
    },
    [onChange],
  );

  const handleEnter = useCallback(
    ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
      if (key === 'Enter' && onSubmit) {
        onSubmit();
      }
    },
    [onSubmit],
  );

  const [focused, setFocused] = useState(!!value);
  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);
  const handleBlur = useCallback(() => {
    setFocused(!!value);
  }, [value]);
  useEffect(() => {
    if (value) {
      setFocused(true);
    }
  }, [value]);

  return (
    <div className="relative flex flex-col h-18 w-full">
      <label>
        <span
          className={`absolute transition-transform origin-left left-3 top-3 font-light ${
            focused
              ? 'scale-75 -translate-y-3 text-slate-400'
              : 'text-slate-500'
          }`}
        >
          {label}
        </span>
        <input
          autoFocus={!!autofocus}
          defaultValue={defaultValue}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          onKeyUp={handleEnter}
          type="text"
          value={value}
          className="bg-slate-100 border-slate-600 border-b rounded-t-sm w-full h-full px-3 pt-5 pb-2 outline-none focus:border-harry-dark focus:bg-slate-200"
        />
      </label>
      <div className="text-sm text-slate-400 px-3">{placeholder}</div>
    </div>
  );
};

export default TextBox;
