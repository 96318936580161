type MealPropsType = { className?: string };

const Meal = ({ className = 'stroke-[3px] stroke-black' }: MealPropsType) => (
  <svg viewBox="0 0 512 512">
    <g>
      <path
        className={className}
        d="M411.928,512H100.072c-39.131,0-70.966-31.835-70.966-70.966v-320.25
		c0-39.131,31.835-70.966,70.966-70.966h311.857c39.131,0,70.966,31.835,70.966,70.966v213.222c0,8.078-6.55,14.629-14.629,14.629
		c-8.08,0-14.629-6.551-14.629-14.629V120.784c0-22.998-18.71-41.707-41.707-41.707H100.072c-22.998,0-41.707,18.71-41.707,41.707
		v320.25c0,22.998,18.71,41.707,41.707,41.707h311.857c22.998,0,41.707-18.71,41.707-41.707v-28.032
		c0-8.078,6.55-14.629,14.629-14.629c8.08,0,14.629,6.551,14.629,14.629v28.032C482.895,480.165,451.059,512,411.928,512z"
      />
      <path
        className={className}
        d="M200.936,444.66c-3.744,0-7.489-1.429-10.345-4.285c-5.713-5.713-5.713-14.976,0-20.691
		L301.16,309.117c5.713-5.711,14.976-5.711,20.69,0c5.713,5.713,5.713,14.976,0,20.69L211.282,440.376
		C208.425,443.233,204.68,444.66,200.936,444.66z"
      />
    </g>
    <path
      className={className}
      d="M368.637,330.158c-19.759,19.759-50.96,20.594-69.69,1.864c-18.73-18.73-17.896-49.932,1.864-69.691
	c19.759-19.759,63.991-33.623,82.721-14.893C402.26,266.168,388.397,310.4,368.637,330.158z"
    />
    <g>
      <path
        className={className}
        d="M332.081,360.171c-16.556,0-31.998-6.323-43.479-17.804c-24.405-24.406-23.568-64.951,1.864-90.381
		c16.504-16.505,44.364-28.032,67.748-28.032c14.735,0,27.066,4.542,35.661,13.137c26.607,26.608,8.656,79.862-14.893,103.411
		C366.299,353.187,349.642,360.171,332.081,360.171z M358.213,253.213c-15.475,0-36.145,8.548-47.059,19.463
		c-14.024,14.022-14.859,36.005-1.864,49.003c5.954,5.953,14.049,9.234,22.79,9.234c9.746,0,19.055-3.941,26.212-11.099
		c16.896-16.894,25.246-51.677,14.893-62.032C369.409,254.005,362.959,253.213,358.213,253.213z"
      />
      <path
        className={className}
        d="M324.274,447.307c-3.744,0-7.489-1.429-10.345-4.285L207.078,336.171
		c-5.713-5.713-5.714-14.976,0-20.691c5.713-5.711,14.978-5.711,20.689,0l106.852,106.851c5.713,5.713,5.713,14.976,0,20.69
		C331.763,445.878,328.018,447.307,324.274,447.307z"
      />
      <path
        className={className}
        d="M180.025,362.299c-10.641,0-20.648-4.145-28.172-11.671l-42.582-42.581
		c-5.713-5.713-5.713-14.976,0-20.69c5.713-5.711,14.976-5.711,20.69,0l42.582,42.582c1.998,2,4.657,3.1,7.483,3.1
		c2.828,0,5.485-1.102,7.483-3.1l34.03-34.03c2-1.998,3.1-4.657,3.1-7.483c0-2.826-1.1-5.485-3.1-7.483l-42.582-42.582
		c-5.713-5.713-5.713-14.976,0-20.69c5.713-5.71,14.976-5.71,20.689,0l42.582,42.582c7.525,7.527,11.67,17.531,11.67,28.173
		s-4.145,20.647-11.67,28.173l-34.03,34.03C200.673,358.155,190.668,362.299,180.025,362.299z"
      />
      <path
        className={className}
        d="M208.131,331.163c-3.745,0-7.487-1.429-10.345-4.285l-53.425-53.427
		c-5.713-5.713-5.713-14.976,0-20.691c5.713-5.711,14.976-5.71,20.689,0l53.425,53.427c5.713,5.713,5.713,14.976,0,20.691
		C215.62,329.736,211.875,331.163,208.131,331.163z"
      />
    </g>
    <path
      className={className}
      d="M468.264,175.561v-54.777c0-31.114-25.223-56.337-56.337-56.337H100.072
	c-31.114,0-56.337,25.223-56.337,56.337v54.779h424.529V175.561z"
    />
    <g>
      <path
        className={className}
        d="M468.265,190.192H43.735c-8.08,0-14.629-6.551-14.629-14.63v-54.779
		c0-39.131,31.835-70.966,70.966-70.966h311.857c39.131,0,70.966,31.835,70.966,70.966v54.779
		C482.895,183.641,476.344,190.192,468.265,190.192z M58.364,160.933h395.271v-40.149c0-22.998-18.71-41.707-41.707-41.707H100.072
		c-22.998,0-41.707,18.71-41.707,41.707C58.364,120.784,58.364,160.933,58.364,160.933z"
      />
      <path
        className={className}
        d="M148.535,100.943c-8.08,0-14.63-6.551-14.63-14.629V14.629c0-8.078,6.55-14.629,14.63-14.629
		c8.08,0,14.629,6.551,14.629,14.629v71.685C163.164,94.392,156.615,100.943,148.535,100.943z"
      />
      <path
        className={className}
        d="M375.222,100.943c-8.08,0-14.629-6.551-14.629-14.629V14.629c0-8.078,6.55-14.629,14.629-14.629
		c8.08,0,14.629,6.551,14.629,14.629v71.685C389.851,94.392,383.301,100.943,375.222,100.943z"
      />
    </g>
  </svg>
);

export default Meal;
