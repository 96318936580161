import ListHeader from '../../ui/list/listHeader';
import { DayOfWeek } from '../types';

export type DayHeaderProps = {
  day: DayOfWeek;
  week: string;
  onBack: () => void;
};

const DayHeader = ({ day, week, onBack }: DayHeaderProps) => {
  return (
    <ListHeader>
      <div className="flex align-center">
        <div>
          <button className="pr-button" onClick={onBack}>
            &laquo;
          </button>
        </div>
        <div>
          Menu of {week}: {day}
        </div>
      </div>
    </ListHeader>
  );
};

export default DayHeader;
