import { splitQuery } from '../../lib/splitQuery';
import { OnSnapshotCallback } from '../../lib/useDataLoader';
import { query, where } from 'firebase/firestore';
import { db } from './db';
import { Side } from '../types';

export const getSidesById = (
  familyId: string,
  ids: Array<string>,
  onSides: OnSnapshotCallback<Record<string, Side>>,
) => {
  splitQuery(
    (subsetIds) => query(db(familyId), where('__name__', 'in', subsetIds)),
    ids,
  ).then((queryResults) => {
    onSides(
      queryResults.reduce(
        (results: { [key: string]: Side }, d) => ({
          ...results,
          [d.id]: {
            id: d.id,
            ...d.data(),
          } as Side,
        }),
        {},
      ),
    );
  });

  return () => null;
};
