import Card, { CardPropsType } from '../card';

type ListCardPropsType = CardPropsType & {
  onClick: Function;
};

const ListCard = ({
  children,
  className = '',
  onClick,
}: React.PropsWithChildren<ListCardPropsType>) => (
  <Card className={className} onClick={onClick}>
    {children}
  </Card>
);

export default ListCard;
