import { useCallback, useEffect, useState } from 'react';
import { captureException } from '@sentry/react';

const ErrorPage = () => {
  const [hasPossibleUpdate, setHasPossibleUpdate] = useState(false);

  useEffect(() => {
    try {
      navigator.serviceWorker.getRegistration().then((registration) => {
        if (!registration) {
          return;
        }

        if (registration.waiting) {
          setHasPossibleUpdate(true);
        }
      });
    } catch (err) {
      console.error(err);
      captureException(err);
    }
  }, []);

  const handleForceRefresh = useCallback(() => {
    navigator.serviceWorker
      .getRegistration()
      .then((registration) => {
        if (registration && registration.waiting) {
          return registration.update();
        }

        return Promise.resolve();
      })
      .then(() => {
        window.location.reload();
      });
  }, []);

  return (
    <div className="flex flex-col justify-center items-center h-screen w-screen">
      <div className="text-5xl">OOPS!</div>
      <div>Something went wrong. Please refresh and try again.</div>
      {hasPossibleUpdate && (
        <div>
          You can try forcing an update by tapping{' '}
          <button onClick={handleForceRefresh}>here</button>.
        </div>
      )}
    </div>
  );
};

export default ErrorPage;
