import { createContext, useCallback, useContext, useState } from 'react';

export const INFO = 'INFO';
export const WARN = 'WARN';
export const ERROR = 'ERROR';

export type NotificationType = {
  level: typeof INFO | typeof WARN | typeof ERROR;
  message: string;
  id?: string;
};

export type NotificationContextType = {
  addNotification: (notification: NotificationType) => void;
  removeNotification: (id: string) => void;
  notifications: Array<NotificationType>;
};

export const NotificationContext = createContext<NotificationContextType>(
  null!,
);

export const useNotifications = () => useContext(NotificationContext);

type NotificationProviderType = {};

const NotificationProvider = ({
  children,
}: React.PropsWithChildren<NotificationProviderType>) => {
  const [notifications, setNotifications] = useState<Array<NotificationType>>(
    [],
  );

  const handleAddNotification = useCallback(
    (notification: NotificationType) => {
      const notificationToAdd = {
        ...notification,
        id: notification.id || Date.now().toString(),
      };

      setNotifications((allNotifications) => [
        notificationToAdd,
        ...allNotifications,
      ]);
    },
    [],
  );

  const handleRemoveNotification = useCallback((removeId: string) => {
    setNotifications((allNotifications) =>
      allNotifications.filter(({ id }) => id !== removeId),
    );
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        addNotification: handleAddNotification,
        removeNotification: handleRemoveNotification,
        notifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
