import { doc, onSnapshot } from 'firebase/firestore';
import { Label } from '../types';
import { db } from './db';
import { OnSnapshotCallback } from '../../lib/useDataLoader';

export const getLabelById = (
  familyId: string,
  id: string,
  onLabel: OnSnapshotCallback<Label>,
) => {
  const unsubscribe = onSnapshot(doc(db(familyId), id), (documentSnapshot) => {
    if (!documentSnapshot.exists) {
      onLabel(null);
      return;
    }

    onLabel({
      id: documentSnapshot.id,
      ...documentSnapshot.data(),
    } as Label);
  });

  return () => {
    unsubscribe();
  };
};
