type LoadingSpinnerPropsType = {
  grow?: boolean;
};

const LoadingSpinner = ({ grow = true }: LoadingSpinnerPropsType) => (
  <div
    className={`absolute w-[40px] h-[40px] ${
      grow ? 'inset-1/2 translate-x-[-20px]' : ''
    }`}
  >
    <div className="absolute animate-expanding w-[40px] h-[40px] border-2 rounded-[40px] border-hermione" />
    <div className="absolute animate-expanding animation-delay-1000 w-[40px] h-[40px] border-2 rounded-[40px] border-hermione" />
  </div>
);

export default LoadingSpinner;
