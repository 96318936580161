import { useCallback, useMemo, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import CreateSideModal from './createSideModal';
import UpdateSideModal from './updateSideModal';
import FloatingAddButton from '../ui/floatingAddButton';
import ListHeader from '../ui/list/listHeader';
import ListItem from '../ui/list/listItem';
import ListContainer from '../ui/list/listContainer';
import ListSearch from '../ui/list/listSearch';
import { Side } from './types';
import { useListSides } from './hooks/useListSides';

type SidesPageProps = {
  sides: Side[];
};

const SidesPage = ({ sides }: SidesPageProps) => {
  const navigate = useNavigate();

  const [query, setQuery] = useState('');
  const handleSearch = useCallback((q: string) => {
    setQuery(q);
  }, []);

  const sortedSides = useMemo(() => {
    const reQuery = new RegExp(query, 'i');

    return sides
      .filter((side) => reQuery.test(side.name))
      .sort((sideA, sideB) => sideA.name.localeCompare(sideB.name));
  }, [sides, query]);

  const handleCloseCreate = useCallback(() => {
    navigate('.', { replace: true });
  }, [navigate]);

  return (
    <>
      <ListHeader>Sides</ListHeader>
      <ListSearch onSearch={handleSearch} />
      <ListContainer>
        {sortedSides.map((side: Side) => (
          <ListItem key={side.id} onClick={() => navigate(side.id)}>
            {side.name}
          </ListItem>
        ))}
      </ListContainer>
      <FloatingAddButton onClick={() => navigate('new')} />
      <Routes>
        <Route
          path="new"
          element={
            <CreateSideModal
              onCancel={handleCloseCreate}
              onClose={handleCloseCreate}
            />
          }
        />
        <Route path=":sideId" element={<UpdateSideModal />} />
      </Routes>
    </>
  );
};

const SidesPageLoader = () => {
  const { loading, data } = useListSides();

  if (loading) {
    return null;
  }

  return <SidesPage sides={data || []} />;
};

export default SidesPageLoader;
