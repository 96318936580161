type CloseXPropsType = { className: string };

const CloseX = ({
  className = 'stroke-[3px] stroke-slate-600',
}: CloseXPropsType) => (
  <svg viewBox="0 0 40 40">
    <path className={className} d="M 10,10 L 30,30 M 30,10 L 10,30" />
  </svg>
);

export default CloseX;
