import { addDoc } from 'firebase/firestore';
import { SideEgg } from '../types';
import { db } from './db';

export const createSide = async (
  side: SideEgg,
  uid: string,
  familyId: string,
) => {
  try {
    return addDoc(db(familyId), { uid, ...side }).then(({ id }) => ({
      id,
      ...side,
    }));
  } catch (e) {
    console.error('Error adding document: ', e);
  }

  throw new Error('Could not create document');
};
