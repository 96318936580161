import { useEffect, useRef } from 'react';
import { captureException } from '@sentry/browser';

export const useWakeLock = () => {
  const isSupported = 'wakeLock' in navigator;
  const wakeLock = useRef<WakeLockSentinel | null>(null);

  useEffect(() => {
    if (!isSupported || document.hidden) {
      return;
    }

    try {
      navigator.wakeLock
        .request('screen')
        .then((sentinel: WakeLockSentinel) => {
          wakeLock.current = sentinel;
        })
        .catch((err: Error) => {
          console.error(err);
          captureException(err);
        });
    } catch (err) {
      console.error(err);
      captureException(err);
    }

    return () => {
      wakeLock.current
        ?.release()
        .then(() => {
          wakeLock.current = null;
        })
        .catch((err: Error) => {
          console.error(err);
          captureException(err);
        });
    };
  }, [isSupported]);
};
