import { useCallback, useState } from 'react';
import { SIDE, trackCreate } from '../tracking';
import Modal from '../ui/modal';
import ModalTitle from '../ui/modal/title';
import ModalBody from '../ui/modal/body';
import ModalFooter from '../ui/modal/footer';
import TextBox from '../form/textbox';
import Button from '../form/button';
import { useConfirmModal } from '../ui/confirm';
import { useListSides } from './hooks/useListSides';
import { useCreateSide } from './hooks/useCreateSide';

type CreateSideModalProps = {
  defaultName?: string;
  onCancel: () => void;
  onClose: (sideId: string) => void;
};

const CreateSideModal = ({
  defaultName = '',
  onCancel,
  onClose,
}: CreateSideModalProps) => {
  const [name, setName] = useState(defaultName);
  const handleNameChange = (newName: string) => {
    setName(newName);
  };

  const [url, setUrl] = useState<string>();
  const handleUrlChange = (newUrl: string) => {
    setUrl(newUrl);
  };

  const { data: allSides } = useListSides();
  const confirmModal = useConfirmModal();

  const createSide = useCreateSide();

  const handleSave = useCallback(() => {
    if (!name || !allSides) {
      return;
    }

    trackCreate(SIDE);

    const existingSide = allSides.find(
      (side) => side.name.toLocaleLowerCase() === name.toLocaleLowerCase(),
    );

    let confirmPromise: Promise<string>;
    if (existingSide) {
      confirmPromise = confirmModal(
        'A side with this name already exists. Create anyway?',
        'No',
        'Yes',
      );
    } else {
      confirmPromise = Promise.resolve('Yes');
    }

    confirmPromise
      .then((answer) => {
        if (answer === 'Yes') {
          return createSide({ name: name.trim(), url: url || '' }).then(
            (side) => {
              onClose(side.id);
            },
          );
        }
      })
      .catch(() => {
        //NOOP
      });
  }, [allSides, confirmModal, createSide, name, onClose, url]);

  return (
    <Modal>
      <ModalTitle onClose={onCancel}>Create Side</ModalTitle>
      <ModalBody>
        <div>
          <TextBox label="Name" onChange={handleNameChange} value={name} />
        </div>
        <div className="mt-2">
          <TextBox label="Recipe URL" onChange={handleUrlChange} value={url} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button use="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Create</Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateSideModal;
