import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ListHeader from '../ui/list/listHeader';
import ListCard from '../ui/list/listCard';
import ListContainer from '../ui/list/listContainer';
import {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  DayOfWeek,
  MenuEgg,
  Menu,
} from './types';
import { fromWeek } from '../lib/fromWeek';
import { toWeek } from '../lib/toWeek';
import { startOfWeek } from '../lib/startOfWeek';
import MenuPickerModal, { MenuPickerOnSaveHandler } from './menuPickerModal';
import LabelModal from './labelModal';
import Today from '../icons/today';
import FloatingMagicButton from '../ui/floatingMagicButton';
import FloatingCancelButton from '../ui/floatingCancelButton';
import FloatingCheckButton from '../ui/floatingCheckButton';
import WeekCard from './weekCard';
import { useConfirmModal } from '../ui/confirm';
import { pickRandom } from '../util/pickRandom';
import { useMagicMode } from '../nav/MagicModeProvider';
import { CANCEL, SAVE, START, trackMagicMode } from '../tracking';
import { deepClone } from '../lib/deepClone';
import LoadingSpinner from '../ui/loadingSpinner';
import { Side } from '../sides/types';
import { Label } from '../labels/types';
import { useGetSidesById } from '../sides/hooks/useGetSidesById';
import { Meal } from '../meals/types';
import { useSearchMealsByLabels } from '../meals/hooks/useSearchMealsByLabels';
import { useGetMealsById } from '../meals/hooks/useGetMealsById';
import { useCreateMenu } from './hooks/useCreateMenu';
import { useUpdateMenu } from './hooks/useUpdateMenu';
import { useGetWeeksMenu } from './hooks/useGetWeeksMenu';
import { saveWeek } from '../lib/lastWeek';
import { useGetAllMeals } from '../meals/hooks/useGetAllMeals';

const DAYS_OF_WEEK: DayOfWeek[] = [
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
];

const EMPTY_WEEK = {
  [SUNDAY]: [],
  [MONDAY]: [],
  [TUESDAY]: [],
  [WEDNESDAY]: [],
  [THURSDAY]: [],
  [FRIDAY]: [],
  [SATURDAY]: [],
};

type WeekPagePropsType = {
  menu: Menu | MenuEgg;
  mealsById: { [key: string]: Meal | undefined };
  sidesById: { [key: string]: Side | undefined };
};

const WeekPage = ({ menu, mealsById, sidesById }: WeekPagePropsType) => {
  const navigate = useNavigate();
  const params = useParams();
  const week = params.week as string;
  const now = useMemo(() => new Date(), []);
  const today = useMemo(() => toWeek(now), [now]);

  const isToday = useCallback(
    (index: number) => {
      const check = fromWeek(week);
      check.setDate(check.getDate() + index);
      return toWeek(check) === today;
    },
    [week, today],
  );

  const [mealDay, setMealDay] = useState<DayOfWeek>();

  const createMenu = useCreateMenu();
  const updateMenu = useUpdateMenu();

  const handleSaveDay: MenuPickerOnSaveHandler = useCallback(
    ({ mealIds, sideIds }) => {
      if (!mealDay) {
        return;
      }

      const newMenu = deepClone<Menu>(menu);

      newMenu.days[mealDay].dinnerIds = mealIds;
      newMenu.days[mealDay].sideIds = sideIds;

      if ('id' in menu) {
        updateMenu(newMenu);
      } else {
        createMenu(newMenu);
      }

      setMealDay(undefined);
    },
    [createMenu, mealDay, updateMenu, menu],
  );

  const sidesByDay: { [key: string]: Array<Side> | undefined } = useMemo(() => {
    return DAYS_OF_WEEK.reduce(
      (acc, dayOfWeek: DayOfWeek) => ({
        ...acc,
        [dayOfWeek]: menu.days[dayOfWeek].sideIds
          ?.map((sideId) => sidesById[sideId])
          .filter(Boolean),
      }),
      {},
    );
  }, [menu, sidesById]);

  const { isMagicMode, setMagicMode } = useMagicMode();
  const [magicMealsByDay, setMagicMealsByDay] = useState<{
    [key: string]: Meal;
  }>({});

  const handlePreviousWeek = useCallback(() => {
    setMagicMealsByDay({});
    setMagicMode(false);
    const date = fromWeek(week);
    navigate(
      `../${toWeek(startOfWeek(new Date(date.setDate(date.getDate() - 1))))}`,
    );
  }, [navigate, setMagicMode, week]);

  const handleNextWeek = useCallback(() => {
    setMagicMealsByDay({});
    setMagicMode(false);
    const date = fromWeek(week);
    navigate(
      `../${toWeek(startOfWeek(new Date(date.setDate(date.getDate() + 7))))}`,
    );
  }, [navigate, setMagicMode, week]);

  const handleGoToToday = useCallback(() => {
    setMagicMealsByDay({});
    setMagicMode(false);
    navigate(`../${toWeek(startOfWeek(now))}`);
  }, [navigate, now, setMagicMode]);

  const handleClear = useCallback(
    (dayOfWeek: DayOfWeek) => {
      const newMenu = deepClone<Menu>(menu);
      delete newMenu.days[dayOfWeek].dinnerIds;
      delete newMenu.days[dayOfWeek].sideIds;

      if ('id' in newMenu) {
        updateMenu(newMenu);
      }
    },
    [menu, updateMenu],
  );

  const [lockedDays, setLockedDays] = useState<Set<DayOfWeek>>(new Set());
  const [labelsByDay, setLabelsByDay] =
    useState<{ [day in DayOfWeek]: Array<Label> }>(EMPTY_WEEK);

  const handleSetLabelsByDay = useCallback(
    (dayOfWeek: DayOfWeek, labels: Array<Label>) => {
      setLabelsByDay((curr) => {
        curr[dayOfWeek] = labels;
        return curr;
      });

      setMealDay(undefined);
    },
    [],
  );

  const handleCancelMagicMode = useCallback(() => {
    setMagicMealsByDay({});
    setMagicMode(false);
    trackMagicMode(CANCEL);
  }, [setMagicMode]);

  const handleSaveMagicMode = useCallback(() => {
    setMagicMode(false);
    Object.keys(magicMealsByDay).forEach((dayOfWeek) => {
      menu.days[dayOfWeek as DayOfWeek].dinnerIds = [
        magicMealsByDay[dayOfWeek].id,
      ];
    });

    if ('id' in menu) {
      updateMenu(menu);
    } else {
      createMenu(menu);
    }

    setMagicMealsByDay({});
    trackMagicMode(SAVE);
  }, [createMenu, magicMealsByDay, menu, setMagicMode, updateMenu]);

  const handleEnterMagicMode = useCallback(() => {
    setLockedDays(
      new Set(
        (Object.keys(menu.days) as Array<DayOfWeek>).filter(
          (dayOfWeek) => !!menu.days[dayOfWeek].dinnerIds?.length,
        ),
      ),
    );
    setMagicMode(true);
    trackMagicMode(START);
  }, [menu.days, setMagicMode]);

  const getAllMeals = useGetAllMeals();
  const searchMealsByLabels = useSearchMealsByLabels();
  const handleMagicMode = useCallback(() => {
    DAYS_OF_WEEK.filter((dayOfWeek) => !lockedDays.has(dayOfWeek)).forEach(
      async (dayOfWeek) => {
        const choicePromise = labelsByDay[dayOfWeek]?.length
          ? searchMealsByLabels(labelsByDay[dayOfWeek].map(({ name }) => name))
          : getAllMeals();

        const meal = await choicePromise.then((choices) =>
          choices ? pickRandom<Meal>(choices) : null,
        );

        if (meal) {
          setMagicMealsByDay((curr) => ({
            ...curr,
            [dayOfWeek]: meal,
          }));
        }
      },
    );
  }, [getAllMeals, labelsByDay, lockedDays, searchMealsByLabels]);

  const confirmModal = useConfirmModal();

  const handleToggleLock = useCallback(
    (dayOfWeek: DayOfWeek) => {
      if (lockedDays.has(dayOfWeek) && menu.days[dayOfWeek].dinnerIds?.length) {
        confirmModal(
          "You've already selected a meal for this day. Clear it and let the magic happen?",
          'No',
          'Yes',
        )
          .then((option) => {
            if (option === 'Yes') {
              handleClear(dayOfWeek);
              setLockedDays((curr) => {
                const s = new Set(curr);

                if (curr.has(dayOfWeek)) {
                  s.delete(dayOfWeek);
                } else {
                  s.add(dayOfWeek);
                }

                return s;
              });
            }
          })
          .catch(() => null);

        return;
      }

      setLockedDays((curr) => {
        const s = new Set(curr);

        if (curr.has(dayOfWeek)) {
          s.delete(dayOfWeek);
        } else {
          s.add(dayOfWeek);
        }

        return s;
      });
    },
    [confirmModal, handleClear, lockedDays, menu.days],
  );

  const handleMagicClickDay = useCallback(
    (dayOfWeek: DayOfWeek) => {
      if (menu.days[dayOfWeek].dinnerIds?.length) {
        confirmModal(
          "You've already selected a meal for this day. Clear it and let the magic happen?",
          'No',
          'Yes',
        )
          .then((option) => {
            if (option === 'Yes') {
              handleClear(dayOfWeek);
              setLockedDays((curr) => {
                const s = new Set(curr);
                s.delete(dayOfWeek);
                return s;
              });
              setMealDay(dayOfWeek);
            }
          })
          .catch(() => null);
      } else if (lockedDays.has(dayOfWeek)) {
        confirmModal('Unlock this day?', 'No', 'Yes')
          .then((option) => {
            if (option === 'Yes') {
              handleClear(dayOfWeek);
              setLockedDays((curr) => {
                const s = new Set(curr);
                s.delete(dayOfWeek);
                return s;
              });
              setMealDay(dayOfWeek);
            }
          })
          .catch(() => null);
      } else {
        setMealDay(dayOfWeek);
      }
    },
    [confirmModal, handleClear, lockedDays, menu.days],
  );

  const handleGoToDay = useCallback(
    (dayOfWeek: DayOfWeek) => {
      navigate(dayOfWeek);
    },
    [navigate],
  );

  return (
    <>
      <ListHeader>
        <div className="flex items-start">
          <div>Menu of {week}</div>
          <button
            className="block w-[25px] h-[25px] ml-4"
            onClick={handleGoToToday}
          >
            <Today className="stroke-[22px] stroke-white" />
          </button>
        </div>
      </ListHeader>
      <ListContainer>
        <ListCard onClick={handlePreviousWeek}>▲ Previous</ListCard>
        {DAYS_OF_WEEK.map((dayOfWeek: DayOfWeek, index: number) => {
          const mealId = menu.days[dayOfWeek].dinnerIds?.length
            ? menu.days[dayOfWeek].dinnerIds?.at(0)
            : undefined;
          const meal = mealId ? mealsById[mealId] : undefined;

          return (
            <WeekCard
              key={dayOfWeek}
              dayOfWeek={dayOfWeek}
              isLocked={lockedDays.has(dayOfWeek)}
              isToday={isToday(index)}
              labels={labelsByDay[dayOfWeek]}
              magicMode={isMagicMode}
              menuItem={menu.days[dayOfWeek]}
              meal={meal}
              magicMeal={magicMealsByDay[dayOfWeek]}
              sides={sidesByDay[dayOfWeek] || []}
              onClick={isMagicMode ? handleMagicClickDay : handleGoToDay}
              onClear={handleClear}
              onToggleLock={handleToggleLock}
            />
          );
        })}
        <ListCard onClick={handleNextWeek}>▼ Next</ListCard>
      </ListContainer>
      <FloatingMagicButton
        onClick={isMagicMode ? handleMagicMode : handleEnterMagicMode}
        sparkle={isMagicMode}
      />
      {isMagicMode && <FloatingCancelButton onClick={handleCancelMagicMode} />}
      {isMagicMode && <FloatingCheckButton onClick={handleSaveMagicMode} />}
      {mealDay && (
        <>
          {isMagicMode ? (
            <LabelModal
              title={mealDay}
              labels={labelsByDay[mealDay]}
              onClose={() => setMealDay(undefined)}
              onSetLabels={(labels) => handleSetLabelsByDay(mealDay, labels)}
            />
          ) : (
            <MenuPickerModal
              title={mealDay}
              week={menu}
              day={mealDay}
              onCancel={() => setMealDay(undefined)}
              onSave={handleSaveDay}
            />
          )}
        </>
      )}
    </>
  );
};

const WeekPageLoader = ({ menu }: { menu: Menu | MenuEgg }) => {
  const mealIds = useMemo(
    () =>
      Object.values(menu.days)
        .flatMap(({ dinnerIds: dayMealIds }) => dayMealIds)
        .filter(Boolean),
    [menu.days],
  );

  const { data: mealsById } = useGetMealsById(mealIds as string[]);

  const sideIds = useMemo(
    () =>
      Object.values(menu.days)
        .flatMap(({ sideIds: daySideIds }) => daySideIds)
        .filter(Boolean),
    [menu.days],
  );

  const { data: sidesById } = useGetSidesById(sideIds as string[]);

  return (
    <WeekPage
      menu={menu}
      mealsById={mealsById || {}}
      sidesById={sidesById || {}}
    />
  );
};

const MenuLoader = ({ week }: { week: string }) => {
  const { data: menu, loading: menuLoading } = useGetWeeksMenu(week);

  useEffect(() => {
    saveWeek(week);
  }, [week]);

  if (menuLoading) {
    return <LoadingSpinner />;
  }

  return <WeekPageLoader menu={menu!} />;
};

/**
 * Ensure we load a fresh component rather than keeping the state between
 * weeks
 */
const CacheBuster = () => {
  const params = useParams<{ week: string }>();
  const week = params.week!;

  return <MenuLoader key={week} week={week} />;
};

export default CacheBuster;
