import CloseX from '../../icons/close-x';

type ModalTitlePropsType = {
  children: React.ReactNode;
  onClose: Function;
};

const ModalTitle = ({ children, onClose }: ModalTitlePropsType) => (
  <div className="relative bg-white text-gryffindor px-4 py-3 font-bold uppercase">
    {children}
    <button
      onClick={() => onClose()}
      className="absolute right-[5px] top-[7px] w-button h-button"
    >
      <CloseX className="stroke-gryffindor stroke-[3px]" />
    </button>
  </div>
);

export default ModalTitle;
