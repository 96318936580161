import { useCallback } from 'react';
import { OnSnapshotCallback, useDataSubscriber } from '../../lib/useDataLoader';
import { useAuth } from '../../auth/useAuth';
import { getUserRating } from '../api/getUserRating';
import { Rating } from '../types';

export const useGetUserRating = (mealId: string) => {
  const { familyId, uid } = useAuth();

  const fetcher = useCallback(
    (handleData: OnSnapshotCallback<Rating>) => {
      if (!familyId || !uid) {
        return;
      }

      return getUserRating({ familyId, uid, mealId }, handleData);
    },
    [mealId, familyId, uid],
  );

  return useDataSubscriber(fetcher);
};
