type ThreeDotsPropsType = { className?: string };

const ThreeDots = ({
  className = 'stroke-[3px] stroke-slate-600',
}: ThreeDotsPropsType) => (
  <svg viewBox="0 0 40 40" className="inline-block">
    <circle className={className} cx="20" cy="25" r="1" />
    <circle className={className} cx="20" cy="15" r="1" />
    <circle className={className} cx="20" cy="5" r="1" />
  </svg>
);

export default ThreeDots;
