import { useCallback } from 'react';
import { useAuth } from '../../auth';
import { searchMealsByLabels } from '../api/searchMealsByLabels';

export const useSearchMealsByLabels = () => {
  const { familyId } = useAuth();

  const fetcher = useCallback(
    (labels: Array<string>) => {
      if (!familyId) {
        return Promise.reject();
      }

      return searchMealsByLabels(familyId, labels);
    },
    [familyId],
  );

  // TODO convert to { data, error, loading } format
  return fetcher;
};
