import { useEffect, useState } from 'react';
import { captureException } from '@sentry/react';
import {
  getMessaging,
  isSupported as isMessagingSupported,
  onMessage,
} from 'firebase/messaging';
import { getMessagingToken } from '../firebase';
import { updatePushToken } from '../auth/families';
import { useAuth } from '../auth/useAuth';
import { INFO, useNotifications } from './NotificationProvider';

const PushNotificationUI = ({ children }: React.PropsWithChildren<{}>) => {
  const { familyId, uid } = useAuth();
  const { addNotification } = useNotifications();

  useEffect(() => {
    if (!familyId || !uid || !navigator.serviceWorker.controller) {
      return;
    }

    getMessagingToken()
      .then((token: string | null) => {
        if (token) {
          updatePushToken(familyId, uid, token);
        }
      })
      .catch((e) => {
        console.error(e);
        captureException(e);
      });
  }, [familyId, uid]);

  useEffect(() => {
    const unsubscribe = onMessage(getMessaging(), (payload) => {
      console.log('Message received. ', payload);
      const { notification } = payload;

      if (!notification) {
        return;
      }

      addNotification({
        level: INFO,
        message: `${notification.title}: ${notification.body}`,
      });
    });

    return unsubscribe;
  }, [addNotification]);

  return <>{children}</>;
};

const PushNotificationSupportWrapper = () => {
  const [isSupported, setIsSupported] = useState(false);

  useEffect(() => {
    isMessagingSupported().then(setIsSupported);
  }, []);

  if (!isSupported) {
    return null;
  }

  return <PushNotificationUI />;
};

export default PushNotificationSupportWrapper;
