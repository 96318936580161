import { useCallback } from 'react';
import { OnSnapshotCallback, useDataSubscriber } from '../../lib/useDataLoader';
import { getRecipeByUrl } from '../api/getRecipeByUrl';
import { Recipe } from '../types';

export const useGetRecipeByUrl = (url: string | null) => {
  const fetcher = useCallback(
    (handleData: OnSnapshotCallback<Recipe>) => {
      if (!url) {
        handleData(null);
        return;
      }

      return getRecipeByUrl(url, handleData);
    },
    [url],
  );

  return useDataSubscriber(fetcher);
};
