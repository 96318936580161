export const DEFAULT = 'DEFAULT';
export const EMBEDDED = 'EMBEDDED';

type ListContainerPropsType = {
  use?: typeof DEFAULT | typeof EMBEDDED;
};

const ListContainer = ({
  children,
  use = DEFAULT,
}: React.PropsWithChildren<ListContainerPropsType>) => (
  <div
    className={`${use === DEFAULT ? 'bg-slate-100 py-2 lg:pl-[250px]' : ''}`}
  >
    {children}
  </div>
);

export default ListContainer;
