import { doc, onSnapshot } from 'firebase/firestore';
import { OnSnapshotCallback } from '../../lib/useDataLoader';
import { Side } from '../types';
import { db } from './db';

export const getSideById = (
  familyId: string,
  id: string,
  onSide: OnSnapshotCallback<Side>,
) => {
  const unsubscribe = onSnapshot(doc(db(familyId), id), (documentSnapshot) => {
    onSide({
      id: documentSnapshot.id,
      ...documentSnapshot.data(),
    } as Side);
  });

  return () => {
    unsubscribe();
  };
};
