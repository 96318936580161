import { onSnapshot, query } from 'firebase/firestore';
import { OnSnapshotCallback } from '../../lib/useDataLoader';
import { Meal } from '../types';
import { db } from './db';

export const listMeals = (
  familyId: string,
  onMeals: OnSnapshotCallback<Array<Meal>>,
) => {
  const q = query(db(familyId));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    onMeals(
      querySnapshot.docs.map((d) => ({
        id: d.id,
        ...d.data(),
      })) as Meal[],
    );
  });

  return () => {
    unsubscribe();
  };
};
