const CircleClose = () => (
  <svg viewBox="0 0 40 40">
    <circle className="fill-slate-300" cx="20" cy="20" r="20" />
    <line
      x1="12"
      y1="12"
      x2="28"
      y2="28"
      className="stroke-white stroke-[6px]"
    />
    <line
      x1="12"
      y1="28"
      x2="28"
      y2="12"
      className="stroke-white stroke-[6px]"
    />
  </svg>
);

export default CircleClose;
