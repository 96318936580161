import Confirm from '../icons/confirm';

type FloatingCheckButtonPropsType = {
  onClick: Function;
};

const FloatingCheckButton = ({ onClick }: FloatingCheckButtonPropsType) => {
  return (
    <button
      onClick={() => onClick()}
      className="bg-ron-light rounded-full w-button-lg h-button-lg fixed bottom-[46px] right-1/4 lg:right-[220px] z-20"
    >
      <Confirm />
    </button>
  );
};

export default FloatingCheckButton;
