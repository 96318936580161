import { useCallback } from 'react';
import { useAuth } from '../../auth';
import { OnSnapshotCallback, useDataSubscriber } from '../../lib/useDataLoader';
import { listMeals } from '../api/listMeals';
import { Meal } from '../types';

export const useListMeals = () => {
  const { familyId } = useAuth();

  const fetcher = useCallback(
    (handleData: OnSnapshotCallback<Array<Meal>>) => {
      if (!familyId) {
        handleData(null);
        return;
      }

      return listMeals(familyId, handleData);
    },
    [familyId],
  );

  return useDataSubscriber(fetcher);
};
