import { useCallback } from 'react';
import ListCard from '../ui/list/listCard';
import MealContextMenu from './mealContextMenu';
import LockButton from '../menus/lockButton';
import { Label } from '../labels/types';
import { Side } from '../sides/types';
import { Meal } from '../meals/types';
import { DayOfWeek, MenuItem } from './types';

type WeekCardPropsType = {
  dayOfWeek: DayOfWeek;
  isLocked: boolean;
  isToday: boolean;
  labels?: Array<Label>;
  magicMode: boolean;
  magicMeal?: Meal;
  meal?: Meal;
  menuItem: MenuItem;
  sides: Array<Side>;
  onClick: (dayOfWeek: DayOfWeek) => void;
  onClear: (dayOfWeek: DayOfWeek) => void;
  onToggleLock: (dayOfWeek: DayOfWeek) => void;
};

const WeekCard = ({
  dayOfWeek,
  isLocked,
  isToday,
  labels = [],
  magicMode,
  magicMeal,
  meal,
  menuItem,
  sides = [],
  onClick,
  onClear,
  onToggleLock,
}: WeekCardPropsType) => {
  const handleClick = useCallback(
    () => onClick(dayOfWeek),
    [dayOfWeek, onClick],
  );
  const handleClear = useCallback(
    () => onClear(dayOfWeek),
    [dayOfWeek, onClear],
  );
  const handleGoToRecipe = useCallback(() => {
    if (!meal) {
      return;
    }
    window.open(meal.url);
  }, [meal]);
  const handleToggleLock = useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation();
      onToggleLock(dayOfWeek);
    },
    [dayOfWeek, onToggleLock],
  );

  return (
    <ListCard
      onClick={handleClick}
      className={`flex flex-row justify-between items-stretch min-h-[80px] ${
        isToday ? '!bg-harry-light' : ''
      }`}
    >
      <div>
        <div
          className={`text-xs ${
            isToday ? 'font-bold underline text-gryffindor' : 'text-slate-400'
          }`}
        >
          {dayOfWeek}
        </div>
        {magicMode ? (
          <>
            <div className={magicMeal ? 'text-hermione-medium font-bold' : ''}>
              {magicMeal ? (
                magicMeal.name
              ) : meal ? (
                meal.name
              ) : (
                <span>&nbsp;</span>
              )}
            </div>
            <div className="font-light text-slate-400">
              {labels.map(({ name }) => name).join(', ')}
            </div>
          </>
        ) : (
          <>
            <div>
              {meal ? meal.name : <span>&nbsp;</span>}
              {!!menuItem?.notes ? <span className="text-xs"> ⓘ </span> : ''}
            </div>
            <div className="font-light text-slate-400">
              {sides.map(({ name }) => name).join(', ')}
            </div>
          </>
        )}
      </div>
      <div className="flex flex-col justify-center">
        {magicMode ? (
          <LockButton isLocked={isLocked} onClick={handleToggleLock} />
        ) : (
          <MealContextMenu
            menuItem={menuItem}
            meal={meal}
            onClear={handleClear}
            onGoToRecipe={handleGoToRecipe}
          />
        )}
      </div>
    </ListCard>
  );
};

export default WeekCard;
