import { useCallback } from 'react';
import { useAuth } from '../../auth/useAuth';
import { updateLabel } from '../api/updateLabel';
import { Label } from '../types';

export const useUpdateLabel = () => {
  const { familyId } = useAuth();

  return useCallback(
    async (label: Label) => {
      if (!familyId) {
        return Promise.reject();
      }

      return updateLabel(familyId, label).then(() => label);
    },
    [familyId],
  );
};
