import { Dispatch, createContext, useContext, useState } from 'react';

export type MagicModeContextType = {
  isMagicMode: boolean;
  setMagicMode: Dispatch<React.SetStateAction<boolean>>;
};

export const MagicModeContext = createContext<MagicModeContextType>(null!);

export const useMagicMode = () => useContext(MagicModeContext);

type MagicModePropsType = {};

const MagicModeProvider = ({
  children,
}: React.PropsWithChildren<MagicModePropsType>) => {
  const [magicMode, setMagicMode] = useState(false);

  return (
    <MagicModeContext.Provider value={{ isMagicMode: magicMode, setMagicMode }}>
      {children}
    </MagicModeContext.Provider>
  );
};

export default MagicModeProvider;
