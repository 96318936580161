type ModalFooterPropsType = {};

const ModalFooter = ({
  children,
}: React.PropsWithChildren<ModalFooterPropsType>) => {
  return (
    <div className="bg-white p-4 flex flex-row justify-end children:ml-4">
      {children}
    </div>
  );
};

export default ModalFooter;
