import {
  addDoc,
  collection,
  doc,
  getDocs,
  updateDoc,
} from 'firebase/firestore';
import { familyDb } from '../../auth/families';
import { Rating, RatingEgg } from '../types';
import { getUserRatingQuery } from './getUserRating';

const db = (familyId: string) => collection(familyDb(familyId), 'ratings');

const getExistingRating = async (
  familyId: string,
  uid: string,
  mealId: string,
) => {
  const q = getUserRatingQuery({
    familyId,
    uid,
    mealId,
  });

  const docSnapshot = await getDocs(q);

  if (docSnapshot.empty) {
    return null;
  }

  const [querySnapshot] = docSnapshot.docs;

  return {
    id: querySnapshot.id,
    ...querySnapshot.data(),
  } as Rating;
};

export const saveUserRating = async (familyId: string, rating: RatingEgg) => {
  try {
    const existingRating = await getExistingRating(
      familyId,
      rating.uid,
      rating.mealId,
    );

    if (!existingRating) {
      return addDoc(db(familyId), { ...rating }).then(({ id }) => ({
        id,
        ...rating,
      }));
    }

    const docRef = doc(db(familyId), existingRating.id);

    await updateDoc(docRef, { ...rating });

    return {
      id: existingRating.id,
      ...rating,
    };
  } catch (e) {
    console.error('Error adding document: ', e);
  }

  throw new Error('Could not create document');
};
