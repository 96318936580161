import { useCallback } from 'react';
import IconToggle, { IconToggleProps } from '../form/iconToggle';
import Lock from '../ui/lock';
import UnLock from '../menus/unlockedIcon';

type LockPropsType = {
  isLocked: boolean;
  onClick: Function;
};

const LockButton = ({ isLocked, onClick }: LockPropsType) => {
  const handleClick: IconToggleProps['onChange'] = useCallback(
    (e) => {
      onClick(e);
    },
    [onClick],
  );

  return (
    <IconToggle
      ariaLabel={isLocked ? 'unlock' : 'lock'}
      icon={
        isLocked ? (
          <Lock className="fill-white" />
        ) : (
          <UnLock className="fill-white/50" />
        )
      }
      onChange={handleClick}
      value={isLocked}
    />
  );
};

export default LockButton;
