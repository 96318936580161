type FlyOutMenuFooterPropsType = {
  children: React.ReactNode;
};

const FlyOutMenuFooter = ({ children }: FlyOutMenuFooterPropsType) => (
  <li className="bg-slate-100 px-2 py-1 text-slate-400 text-sm rounded-b-md text-right">
    {children}
  </li>
);
export default FlyOutMenuFooter;
