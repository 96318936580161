type WandPropsType = { className?: string; sparkle: boolean };

const Wand = ({
  className = 'stroke-[3px] fill-white',
  sparkle,
}: WandPropsType) => (
  <svg viewBox="-150 -110 800 800" className={className}>
    <g>
      <g>
        <path
          d="M454.321,219.727l-38.766-51.947l20.815-61.385c2.046-6.032,0.489-12.704-4.015-17.208
           c-4.504-4.504-11.175-6.061-17.208-4.015l-61.384,20.815l-51.951-38.766c-5.103-3.809-11.929-4.392-17.605-1.499
           c-5.676,2.893-9.217,8.755-9.136,15.125l0.829,64.815l-52.923,37.426c-5.201,3.678-7.863,9.989-6.867,16.282
           c0.996,6.291,5.479,11.471,11.561,13.363l43.844,13.63L14.443,483.432c-6.535,6.534-6.535,17.131,0,23.666s17.131,6.535,23.666,0
           l257.073-257.072l13.629,43.843c2.172,6.986,8.638,11.768,15.984,11.768c5.375,0,10.494-2.595,13.66-7.072l37.426-52.923
           l64.815,0.828c6.322,0.051,12.233-3.462,15.125-9.136S458.131,224.833,454.321,219.727z"
        />
      </g>
    </g>
    <g className={sparkle ? 'animate-sparkle animation-delay-100' : ''}>
      <g>
        <polygon points="173.373,67.274 160.014,42.848 146.656,67.274 122.23,80.632 146.656,93.992 160.014,118.417 173.373,93.992 197.799,80.632" />
      </g>
    </g>
    <g className={sparkle ? 'animate-sparkle animation-delay-300' : ''}>
      <g>
        <polygon points="362.946,384.489 352.14,364.731 341.335,384.489 321.577,395.294 341.335,406.1 352.14,425.856 362.946,406.1 382.703,395.294" />
      </g>
    </g>
    <g className={sparkle ? 'animate-sparkle animation-delay-700' : ''}>
      <g>
        <polygon points="378.142,19.757 367.337,0 356.531,19.757 336.774,30.563 356.531,41.369 367.337,61.126 378.142,41.369 397.9,30.563" />
      </g>
    </g>
    <g className={sparkle ? 'animate-sparkle animation-delay-200' : ''}>
      <g>
        <polygon points="490.635,142.513 484.167,130.689 477.701,142.513 465.876,148.979 477.701,155.446 484.167,167.27 490.635,155.446 502.458,148.979" />
      </g>
    </g>
    <g className={sparkle ? 'animate-sparkle animation-delay-400' : ''}>
      <g>
        <polygon points="492.626,294.117 465.876,301.951 439.128,294.117 446.962,320.865 439.128,347.615 465.876,339.781 492.626,347.615 484.791,320.865" />
      </g>
    </g>
  </svg>
);

export default Wand;
