import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../useAuth';
import RedirectToFamilyUrl from './RedirectToFamilyUrl';

const LookupFamily = () => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.uid || !auth.familyId) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <RedirectToFamilyUrl app="menu" path="menus" />;
};

export default LookupFamily;
