type ListItemPropsType = {
  children: React.ReactNode;
  className?: string;
  onClick: Function;
};

const ListItem = ({ children, className = '', onClick }: ListItemPropsType) => (
  <div
    className={`relative bg-white p-4 border-b ${className}`}
    onClick={() => onClick()}
  >
    {children}
  </div>
);

export default ListItem;
