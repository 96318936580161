import { logEvent } from 'firebase/analytics';
import { getAnalytics } from './firebase';

export const trackLogin = () => {
  logEvent(getAnalytics(), 'login');
};

export const MEAL = 'meal';
export const LABEL = 'label';
export const SIDE = 'SIDE';

type CreateType = typeof MEAL | typeof LABEL | typeof SIDE;

export const trackCreate = (createType: CreateType) => {
  logEvent(getAnalytics(), 'create_item', { createType });
};

export const FAMILY = 'family';
export const APP = 'app';

type InviteType = typeof FAMILY | typeof APP;

export const trackInvite = (inviteType: InviteType) => {
  logEvent(getAnalytics(), 'invite', { inviteType });
};

export const START = 'start';
export const CANCEL = 'cancel';
export const SAVE = 'save';

type ActionType = typeof START | typeof CANCEL | typeof SAVE;

export const trackMagicMode = (action: ActionType) => {
  logEvent(getAnalytics(), 'magic', { action });
};
