import { useCallback } from 'react';
import { useAuth } from '../../auth';
import { useCreateLabel } from '../../labels/hooks/useCreateLabel';
import { updateMeal } from '../api/updateMeal';
import { Meal } from '../types';

export const useUpdateMeal = () => {
  const { familyId } = useAuth();
  const createLabel = useCreateLabel();

  return useCallback(
    async (meal: Meal) => {
      if (!familyId) {
        return Promise.reject();
      }

      meal.labels.forEach((label) => {
        createLabel({ name: label });
      });

      return updateMeal(familyId, meal).then(() => meal);
    },
    [createLabel, familyId],
  );
};
