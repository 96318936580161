import BottomBarNav from './bottomBarNav';
import MagicModeProvider from './MagicModeProvider';

type AppWithNavPropsType = {
  children: React.ReactNode;
};

const AppWithNav = ({ children }: AppWithNavPropsType) => {
  return (
    <MagicModeProvider>
      <div className="pb-[70px] lg:pb-0 min-h-screen bg-slate-100 lg:relative">
        {children}
        <BottomBarNav />
      </div>
    </MagicModeProvider>
  );
};

export default AppWithNav;
