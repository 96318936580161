import { useCallback } from 'react';
import { OnSnapshotCallback, useDataSubscriber } from '../../lib/useDataLoader';
import { useAuth } from '../../auth/useAuth';
import { getSideById } from '../api/getSideById';
import { Side } from '../types';

export const useGetSideById = (id: string) => {
  const { familyId } = useAuth();

  const fetcher = useCallback(
    (handleData: OnSnapshotCallback<Side>) => {
      if (!familyId) {
        handleData(null);
        return;
      }

      return getSideById(familyId, id, handleData);
    },
    [familyId, id],
  );

  return useDataSubscriber(fetcher);
};
