import { createPortal } from 'react-dom';

interface ContainerPropsType {
  children: React.ReactNode;
}

const Overlay = ({ children }: ContainerPropsType) =>
  createPortal(
    <div className="fixed bg-gray-800 bg-opacity-25 w-screen h-screen top-0 left-0 z-30 backdrop-blur-sm">
      {children}
    </div>,
    document.body,
  );

const Content = ({ children }: ContainerPropsType) => (
  <div className="border rounded border-solid border-transparent overflow-hidden w-80 flex flex-col">
    {children}
  </div>
);

interface ModalPropsType extends ContainerPropsType {
  children: React.ReactNode;
}

const Modal = ({ children }: ModalPropsType) => (
  <Overlay>
    <div className="flex flex-col w-screen h-screen justify-start items-center mt-2">
      <Content>{children}</Content>
    </div>
  </Overlay>
);

export default Modal;
