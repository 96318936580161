import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextArea, { TextAreaOnChangeHandler } from '../form/textarea';
import { deepClone } from '../lib/deepClone';
import { useDebounce } from '../lib/useDebounce';
import { useGetMealsById } from '../meals/hooks/useGetMealsById';
import { Meal } from '../meals/types';
import { useGetSidesById } from '../sides/hooks/useGetSidesById';
import { Side } from '../sides/types';
import Card from '../ui/card';
import FloatingAddButton from '../ui/floatingAddButton';
import ListContainer from '../ui/list/listContainer';
import ListSectionHeader from '../ui/list/listSectionHeader';
import LoadingSpinner from '../ui/loadingSpinner';
import DayHeader from './components/DayHeader';
import MealCard from './components/MealCard';
import SideCard from './components/SideCard';
import { useConfirmRemoveMeal } from './hooks/useConfirmRemoveMeal';
import { useConfirmRemoveSide } from './hooks/useConfirmRemoveSide';
import { useCreateMenu } from './hooks/useCreateMenu';
import { useGetWeeksMenu } from './hooks/useGetWeeksMenu';
import { useUpdateMenu } from './hooks/useUpdateMenu';
import MenuPickerModal, { MenuPickerOnSaveHandler } from './menuPickerModal';
import { DayOfWeek, Menu } from './types';
import WeekNavigatorStrip from './weekNavigatorStrip';

const DayPage = ({
  menu,
  meals,
  sides,
}: {
  menu: Menu;
  meals: Array<Meal>;
  sides: Array<Side>;
}) => {
  const navigate = useNavigate();
  const params = useParams<{ week: string; day: DayOfWeek }>();
  const week = params.week!;
  const day = params.day!;

  const [showPicker, setShowPicker] = useState(
    !menu.days[day].dinnerIds?.length &&
      !menu.days[day].sideIds?.length &&
      !menu.days[day].notes,
  );

  const updateMenu = useUpdateMenu();
  const createMenu = useCreateMenu();

  const handleSaveDay: MenuPickerOnSaveHandler = useCallback(
    ({ mealIds, sideIds }) => {
      if (!day) {
        return;
      }

      const newMenu = deepClone<Menu>(menu);

      newMenu.days[day].dinnerIds = mealIds;
      newMenu.days[day].sideIds = sideIds;

      if ('id' in menu) {
        updateMenu(newMenu);
      } else {
        createMenu(newMenu);
      }

      setShowPicker(false);
    },
    [createMenu, day, updateMenu, menu],
  );

  const [notes, setNotes] = useState(menu.days[day].notes);
  const handleSaveNotes = useCallback(() => {
    const newMenu = deepClone<Menu>(menu);

    newMenu.days[day].notes = notes;

    if ('id' in menu) {
      updateMenu(newMenu);
    } else {
      createMenu(newMenu);
    }
  }, [day, menu, notes, createMenu, updateMenu]);

  const debouncedSaveNotes = useDebounce(handleSaveNotes);

  const handleChangeNotes: TextAreaOnChangeHandler = useCallback(
    (value) => {
      setNotes(value);
      debouncedSaveNotes();
    },
    [debouncedSaveNotes],
  );

  const handleConfirmRemoveMeal = useConfirmRemoveMeal(menu, day);
  const handleConfirmRemoveSide = useConfirmRemoveSide(menu, day);

  const handleAdd = useCallback(() => {
    setShowPicker(true);
  }, []);

  const handleBack = useCallback(() => {
    navigate(`../${params.week}`);
  }, [navigate, params]);

  return (
    <div className="min-h-screen flex flex-col">
      <DayHeader day={day} week={week} onBack={handleBack} />
      <div className="flex flex-row items-stretch grow">
        <WeekNavigatorStrip />
        <div className="grow min-w-0">
          <ListContainer>
            {meals.length ? <ListSectionHeader>Meals</ListSectionHeader> : null}
            {meals.map((meal) => (
              <MealCard
                key={meal.id}
                meal={meal}
                onRemoveMeal={handleConfirmRemoveMeal}
              />
            ))}
            {sides.length ? <ListSectionHeader>Sides</ListSectionHeader> : null}
            {Object.values(sides).map((side) => (
              <SideCard
                key={side.id}
                side={side}
                onRemoveSide={handleConfirmRemoveSide}
              />
            ))}
            <ListSectionHeader>Notes</ListSectionHeader>
            <Card>
              <TextArea value={notes} onChange={handleChangeNotes} />
            </Card>
          </ListContainer>
        </div>
      </div>
      <FloatingAddButton onClick={handleAdd} />
      {showPicker && (
        <MenuPickerModal
          title={day}
          week={menu}
          day={day as DayOfWeek}
          onCancel={() => setShowPicker(false)}
          onSave={handleSaveDay}
        />
      )}
    </div>
  );
};

const isMeal = (m: Meal | undefined): m is Meal => Boolean(m);

const DayPageLoader = ({ week, day }: { week: string; day: DayOfWeek }) => {
  const { data: menu, loading: menuLoading } = useGetWeeksMenu(week);

  const mealIds = useMemo(
    () => menu?.days[day]?.dinnerIds || [],
    [day, menu?.days],
  );
  const { data: mealsData } = useGetMealsById(mealIds);

  const sideIds = useMemo(
    () => menu?.days[day]?.sideIds || [],
    [day, menu?.days],
  );
  const { data: sidesData } = useGetSidesById(sideIds);

  if (menuLoading) {
    return <LoadingSpinner />;
  }

  return (
    <DayPage
      menu={menu as Menu}
      meals={Object.values(mealsData || {}).filter(isMeal) || []}
      sides={Object.values(sidesData || {}) || []}
    />
  );
};

/**
 * Ensure we load a fresh component rather than keeping the state between
 * weeks
 */
const CacheBuster = () => {
  const params = useParams<{ week: string; day: DayOfWeek }>();
  const week = params.week!;
  const day = params.day!;

  return <DayPageLoader key={`${week}-${day}`} week={week} day={day} />;
};

export default CacheBuster;
