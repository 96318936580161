type ModalBodyPropsType = {
  children: React.ReactNode;
};

const ModalBody = ({ children }: ModalBodyPropsType) => (
  <div className="bg-white grow p-4 max-h-[calc(80vh)] overflow-scroll">
    {children}
  </div>
);

export default ModalBody;
