import { getDocs, query } from 'firebase/firestore';
import { Meal } from '../types';
import { db } from './db';

export const getAllMeals = async (familyId: string) => {
  const q = query(db(familyId));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((d) => ({
    id: d.id,
    ...d.data(),
  })) as Meal[];
};
