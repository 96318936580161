import { MetricType, onCLS, onFCP, onLCP, onTTFB } from 'web-vitals';

const reportWebVitals = (
  onPerfEntry: (metric: MetricType) => void | undefined,
) => {
  if (onPerfEntry) {
    onCLS(onPerfEntry);
    onFCP(onPerfEntry);
    onLCP(onPerfEntry);
    onTTFB(onPerfEntry);
  }
};

export default reportWebVitals;
