import { Navigate, Routes, Route } from 'react-router-dom';
import InviteAcceptPage from './accept';

const InvitePage = () => (
  <Routes>
    <Route path=":inviteToken" element={<InviteAcceptPage />} />
    <Route path="*" element={<Navigate to="/login" />} />
  </Routes>
);

export default InvitePage;
