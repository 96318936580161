type MenuPropsType = { className?: string };

const Menu = ({ className = 'stroke-[3px] stroke-black' }: MenuPropsType) => (
  <svg viewBox="0 0 330 330">
    <g>
      <path
        className={className}
        d="M315,0H15C6.716,0,0,6.716,0,15v300c0,8.284,6.716,15,15,15h300c8.284,0,15-6.716,15-15V15C330,6.716,323.284,0,315,0z
		 M300,300H30V30h270V300z"
      />
      <path
        className={className}
        d="M90.001,109.999h150c8.284,0,15-6.716,15-15s-6.716-15-15-15h-150c-8.284,0-15,6.716-15,15S81.717,109.999,90.001,109.999z
		"
      />
      <path
        className={className}
        d="M90.001,179.999h150c8.284,0,15-6.716,15-15c0-8.284-6.716-15-15-15h-150c-8.284,0-15,6.716-15,15
		C75.001,173.283,81.717,179.999,90.001,179.999z"
      />
      <path
        className={className}
        d="M90.001,249.999h150c8.284,0,15-6.716,15-15c0-8.284-6.716-15-15-15h-150c-8.284,0-15,6.716-15,15
		C75.001,243.283,81.717,249.999,90.001,249.999z"
      />
    </g>
  </svg>
);

export default Menu;
