import { onSnapshot, query } from 'firebase/firestore';
import { OnSnapshotCallback } from '../../lib/useDataLoader';
import { Label } from '../types';
import { db } from './db';

export const listLabels = (
  familyId: string,
  onLabels: OnSnapshotCallback<Array<Label>>,
) => {
  const q = query(db(familyId));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    onLabels(
      querySnapshot.docs.map((d) => ({
        id: d.id,
        ...d.data(),
      })) as Label[],
    );
  });

  return () => {
    unsubscribe();
  };
};
