import { useCallback } from 'react';
import { useAuth } from '../../auth';
import { deleteLabel } from '../api/deleteLabel';

export const useDeleteLabel = () => {
  const { familyId } = useAuth();

  return useCallback(
    (id: string) => {
      if (!familyId) {
        return Promise.reject();
      }

      return deleteLabel(familyId, id);
    },
    [familyId],
  );
};
