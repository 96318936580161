import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from '../ui/modal';
import ModalTitle from '../ui/modal/title';
import ModalBody from '../ui/modal/body';
import ModalFooter from '../ui/modal/footer';
import TextBox from '../form/textbox';
import Button from '../form/button';
import { useDebounce } from '../lib/useDebounce';
import { Side } from './types';
import { useUpdateSide } from './hooks/useUpdateSide';
import { useDeleteSide } from './hooks/useDeleteSide';
import { useGetSideById } from './hooks/useGetSideById';

type UpdateSideModalProps = {
  side: Side;
};

const UpdateSideModal = ({ side }: UpdateSideModalProps) => {
  const navigate = useNavigate();

  const handleClose = useCallback(
    () => navigate('../', { replace: true }),
    [navigate],
  );

  const [name, setName] = useState<string>(side.name);
  const [url, setUrl] = useState<string>(side.url || '');

  const updateSide = useUpdateSide();

  const debounceSave = useDebounce(() => {
    if (side && name) {
      updateSide({ ...side, name: name.trim(), url: url || '' });
    }
  });

  const handleNameChange = (newName: string) => {
    setName(newName);
    debounceSave();
  };
  const handleUrlChange = (newUrl: string) => {
    setUrl(newUrl);
    debounceSave();
  };

  const deleteSide = useDeleteSide();
  const handleDeleteSide = useCallback(() => {
    if (!side) {
      return;
    }

    deleteSide(side.id).then(handleClose);
  }, [deleteSide, side, handleClose]);

  return (
    <Modal>
      <ModalTitle onClose={handleClose}>Create Side</ModalTitle>
      <ModalBody>
        <div>
          <TextBox label="Name" onChange={handleNameChange} value={name} />
        </div>
        <TextBox
          label="Recipe URL"
          onChange={handleUrlChange}
          value={url || undefined}
        />
      </ModalBody>
      <ModalFooter>
        <Button use="destructive" onClick={handleDeleteSide}>
          Delete
        </Button>
        <Button use="secondary" onClick={handleClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const UpdateSideModalLoader = () => {
  const { sideId = '' } = useParams();
  const { loading, data } = useGetSideById(sideId);

  if (loading) {
    return null;
  }

  return <UpdateSideModal side={data!} />;
};

export default UpdateSideModalLoader;
