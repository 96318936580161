import { useCallback } from 'react';
import TextBox from '../form/textbox';
import Plus from '../icons/plus';

type LabelInputPropsType = {
  onAdd: () => void;
  onChange: (label: string) => void;
  label: string;
};

const LabelInput = ({ label, onAdd, onChange }: LabelInputPropsType) => {
  const handleChange = useCallback(
    (value: string) => onChange(value.toLocaleLowerCase().trim()),
    [onChange],
  );

  return (
    <div className="flex">
      <div className="flex-grow">
        <TextBox
          onChange={handleChange}
          onSubmit={onAdd}
          label="Label"
          value={label}
        />
      </div>
      <div className="w-[60px] relative">
        <button
          onClick={onAdd}
          className="absolute top-2 right-2 bg-ron rounded-full block ml-2 w-button h-button"
        >
          <Plus className="stroke-white stroke-[3px]" />
        </button>
      </div>
    </div>
  );
};

export default LabelInput;
