import { useCallback, useMemo } from 'react';
import { useAuth } from '../../auth';
import { OnSnapshotCallback, useDataSubscriber } from '../../lib/useDataLoader';
import { getWeeksMenu } from '../api/getWeeksMenu';
import { Menu, MenuEgg } from '../types';

export const useGetWeeksMenu = (week: string) => {
  const { familyId } = useAuth();

  const fetcher = useCallback(
    (
      handleData: OnSnapshotCallback<MenuEgg | Menu>,
      variables: { week: string } | undefined,
    ) => {
      if (!familyId || !variables?.week) {
        handleData(null);
        return;
      }

      return getWeeksMenu(familyId, variables?.week, handleData);
    },
    [familyId],
  );

  const opts = useMemo(() => ({ cache: true, variables: { week } }), [week]);

  return useDataSubscriber(fetcher, opts);
};
