type FloatingButtonPropsType = {
  ariaLabel?: string;
  onClick: Function;
};

const FloatingButton = ({
  ariaLabel,
  children,
  onClick,
}: React.PropsWithChildren<FloatingButtonPropsType>) => {
  return (
    <button
      aria-label={ariaLabel}
      onClick={() => onClick()}
      className="bg-ron rounded-full w-[60px] h-[60px] fixed bottom-[36px] lg:right-[70px] left-1/2 lg:left-auto -translate-x-1/2 z-30"
    >
      {children}
    </button>
  );
};

export default FloatingButton;
