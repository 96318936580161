type FlyOutMenuItemPropsType = {
  children: React.ReactNode;
  onClick: () => void;
};

const FlyOutMenuItem = ({ children, onClick }: FlyOutMenuItemPropsType) => (
  <li className="border-b-2 px-2 py-4 last:border-b-0" onClick={onClick}>
    {children}
  </li>
);
export default FlyOutMenuItem;
