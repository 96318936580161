import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import ListHeader from '../ui/list/listHeader';
import ListContainer from '../ui/list/listContainer';
import LoadingSpinner from '../ui/loadingSpinner';
import { useGetRecipeByUrl } from './hooks/useGetRecipeByUrl';
import ListSectionHeader from '../ui/list/listSectionHeader';
import Card from '../ui/card';
import { useWakeLock } from '../wakeLock/hooks/useWakeLock';

const RecipePage = () => {
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const recipeUrl = query.get('url');
  const { data: recipe, loading } = useGetRecipeByUrl(recipeUrl);

  useWakeLock();

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (loading || !recipeUrl) {
    return <LoadingSpinner />;
  }

  if (!recipe) {
    const enqueueRecipeParse = httpsCallable(
      getFunctions(),
      'enqueuerecipeparse',
    );
    enqueueRecipeParse({ recipeUrl }).then(() => {
      window.location.href = recipeUrl;
    });

    return <>Redirecting...</>;
  }

  return (
    <>
      <ListHeader>
        <div className="flex items-start">
          <button className="pr-button" onClick={handleBack}>
            &laquo;
          </button>
          <div>Nana's Recipes</div>
        </div>
      </ListHeader>
      <ListContainer>
        <ListSectionHeader>{recipe.title}</ListSectionHeader>
        <Card>
          {recipe.description}
          <p className="mt-4">
            <a href={recipeUrl} target="_blank" rel="noreferrer">
              Go to original recipe
            </a>
          </p>
        </Card>
        <ListSectionHeader>Ingredients</ListSectionHeader>
        <Card>
          <ul>
            {recipe.ingredients.map((ingredient) => (
              <li
                className="border-b border-solid border-slate-200 py-2"
                key={ingredient}
              >
                {ingredient}
              </li>
            ))}
          </ul>
        </Card>
        <ListSectionHeader>Steps</ListSectionHeader>
        <Card>
          <ul>
            {recipe.instructions
              .sort((a, b) => a.step - b.step)
              .map((instruction) => (
                <li
                  className="border-b border-solid border-slate-200 py-2"
                  key={instruction.step}
                >
                  {instruction.step + 1}: {instruction.instruction}
                </li>
              ))}
          </ul>
        </Card>
      </ListContainer>
    </>
  );
};

export default RecipePage;
