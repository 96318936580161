const fallback: { [key: string]: string } = {};

export const getItem = (key: string): string | null => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    console.error(e);
  }

  return fallback[key];
};

export const setItem = (key: string, value: string): void => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    console.error(e);
  }

  fallback[key] = value;
};

export const removeItem = (key: string): void => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.error(e);
  }

  delete fallback[key];
};
