export const SUNDAY = 'SUNDAY';
export const MONDAY = 'MONDAY';
export const TUESDAY = 'TUESDAY';
export const WEDNESDAY = 'WEDNESDAY';
export const THURSDAY = 'THURSDAY';
export const FRIDAY = 'FRIDAY';
export const SATURDAY = 'SATURDAY';

export type DayOfWeek =
  | typeof SUNDAY
  | typeof MONDAY
  | typeof TUESDAY
  | typeof WEDNESDAY
  | typeof THURSDAY
  | typeof FRIDAY
  | typeof SATURDAY;

export type MenuItem = {
  mealId?: string;
  dinnerIds?: Array<string>;
  sideIds?: Array<string>;
  notes?: string | undefined;
};

export interface MenuEgg {
  week: string;
  days: {
    [day in DayOfWeek]: MenuItem;
  };
}

export interface Menu extends MenuEgg {
  id: string;
}
