import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import AppWithNav from '../../nav';
import PushNotificationUI from '../../notifications/PushNotificationUI';
import { useAuth } from '../useAuth';
import RedirectToFamilyUrl from './RedirectToFamilyUrl';

const RequiredAuth = () => {
  const auth = useAuth();
  const location = useLocation();
  const { familyId } = useParams();

  if (!auth.uid) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (familyId && !auth.validateAccess(familyId)) {
    return <RedirectToFamilyUrl />;
  }

  return (
    <AppWithNav>
      <PushNotificationUI />
      <Outlet />
    </AppWithNav>
  );
};

export default RequiredAuth;
