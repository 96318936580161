import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getRedirectResult,
  signInWithRedirect,
  GoogleAuthProvider,
} from 'firebase/auth';
import { getAuth } from '../firebase';
import { trackLogin } from '../tracking';
import { useAuth } from '../auth';
import Button from '../form/button';
import LoadingSpinner from '../ui/loadingSpinner';

const provider = new GoogleAuthProvider();

const LoginPage = () => {
  const firebaseAuth = getAuth();
  const auth = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const handleSignIn = useCallback(() => {
    signInWithRedirect(firebaseAuth, provider);
  }, [firebaseAuth]);

  useEffect(() => {
    getRedirectResult(firebaseAuth)
      .then((result) => {
        setLoading(false);

        if (!result) {
          return;
        }

        trackLogin();
        auth.signIn(result.user.uid).then(() => navigate('/'));
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [auth, firebaseAuth, navigate]);

  return (
    <div className="flex w-screen h-screen items-center justify-center flex-col">
      {loading || auth.uid ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="text-center my-8 px-3">
            <h2 className="font-bold text-lg my-2">Welcome to Menu Magic!</h2>
            If you received a summoning link, click that in order to get access.
            Otherwise, ask a friend to summon you!
          </div>
          <Button onClick={handleSignIn}>Sign In</Button>
        </>
      )}
    </div>
  );
};

export default LoginPage;
