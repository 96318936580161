import FloatingButton from './floatingButton';
import Wand from '../icons/wand';

type FloatingMagicButtonPropsType = {
  onClick: Function;
  sparkle: boolean;
};

const FloatingMagicButton = ({
  onClick,
  sparkle,
}: FloatingMagicButtonPropsType) => (
  <FloatingButton ariaLabel="magic-wand" onClick={onClick}>
    <Wand sparkle={sparkle} />
  </FloatingButton>
);

export default FloatingMagicButton;
