import { useCallback, useMemo, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import CreateLabelModal from './createLabelModal';
import UpdateLabelModal from './updateLabelModal';
import FloatingAddButton from '../ui/floatingAddButton';
import ListHeader from '../ui/list/listHeader';
import ListItem from '../ui/list/listItem';
import ListContainer from '../ui/list/listContainer';
import ListSearch from '../ui/list/listSearch';
import { useListLabels } from './hooks/useListLabels';

const LabelsPage = () => {
  const navigate = useNavigate();

  const { data: labels } = useListLabels();

  const [query, setQuery] = useState('');
  const handleSearch = useCallback((q: string) => {
    setQuery(q);
  }, []);

  const sortedLabels = useMemo(() => {
    if (!labels) {
      return [];
    }

    const reQuery = new RegExp(query, 'i');

    return labels
      .filter((label) => reQuery.test(label.name))
      .sort((labelA, labelB) => labelA.name.localeCompare(labelB.name));
  }, [labels, query]);

  return (
    <>
      <ListHeader>Labels</ListHeader>
      <ListSearch onSearch={handleSearch} />
      <ListContainer>
        {sortedLabels.map((label) => (
          <ListItem key={label.id} onClick={() => navigate(label.id)}>
            {label.name}
          </ListItem>
        ))}
      </ListContainer>
      <FloatingAddButton onClick={() => navigate('new')} />
      <Routes>
        <Route path="new" element={<CreateLabelModal />} />
        <Route path=":labelId" element={<UpdateLabelModal />} />
      </Routes>
    </>
  );
};

export default LabelsPage;
