import { useMemo } from 'react';

export type ForkProps = { color?: string; fillPercent: number };

export const Fork = ({ color = 'fill-slate-600', fillPercent }: ForkProps) => {
  const clipStyle = useMemo(() => {
    return {
      clipPath: `inset(${
        (1 - (fillPercent > 0 ? fillPercent : 1)) * 100
      }% 0 0)`,
    };
  }, [fillPercent]);

  return (
    <svg
      viewBox="0 0 200 900"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      style={clipStyle}
    >
      <path
        className={`stroke-[3px] stroke-slate-600 ${
          fillPercent === 0 ? 'fill-slate-100' : color
        }`}
        d="M50 266c-3,157 -8,338 -31,491 -21,140 114,151 93,1 -21,-154 -26,-335 -28,-493 26,-6 47,-28 47,-64l-12 -171c-1,-14 0,-27 -14,-28l0 194c0,10 -17,10 -17,0l0 -196c-5,0 -9,0 -14,0l0 196c0,10 -17,10 -17,0l0 -196c-5,0 -10,0 -14,0l0 196c0,10 -17,10 -17,0l0 -194c-15,1 -13,13 -14,27l-12 172c0,37 23,59 50,65z"
      />
    </svg>
  );
};
