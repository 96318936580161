import { useCallback, useEffect, useMemo, useState } from 'react';

type DataLoaderOptionsType = {
  skip?: boolean;
};

export const ERROR_MISSING = 'ERROR_MISSING';

type DataLoaderErrorCode = typeof ERROR_MISSING;

type DataLoaderErrorType = {
  code: DataLoaderErrorCode;
};

export type DataLoaderResponseType<T> = {
  error?: DataLoaderErrorType;
  loading: boolean;
  data: T;
};

export type FetcherType<T> = (
  setData: (d: T) => void,
  setLoading: (b: boolean) => void,
  setError: (e: DataLoaderErrorCode) => void,
) => void;

export const useDataLoader = <T>(
  fetcher: FetcherType<T>,
  opts?: DataLoaderOptionsType,
): DataLoaderResponseType<T> => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<DataLoaderErrorType>(null!);
  const [data, setData] = useState<T>(null!);

  const handleError = useCallback((code: DataLoaderErrorCode) => {
    setError({ code });
  }, []);

  const handleData = useCallback((newData: T) => {
    setData(newData);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (opts?.skip) {
      setLoading(false);
      return;
    }

    setLoading(true);

    return fetcher(handleData, setLoading, handleError);
  }, [fetcher, opts, handleData, handleError]);

  return { data, loading, error };
};

const buildCacheKey = <T, R>(
  fetcher: (
    handleData: (data: T | null) => void,
    variables?: DataSubscriberOptions<R>['variables'],
  ) => OnSnapshotUnsubscribe,
  opts?: DataSubscriberOptions<R>,
) => {
  if (!opts?.cache) {
    return undefined;
  }

  if (!opts?.variables) {
    return fetcher.toString();
  }

  return `${fetcher.toString()}${JSON.stringify(opts.variables)}`;
};

const cache: Record<string, any> = {};

export type DataSubscriberOptions<R extends Record<keyof R, any>> = {
  cache?: boolean;
  skip?: boolean;
  variables?: R;
};

export type OnSnapshotCallback<T> = (data: T | null) => void;
export type OnSnapshotUnsubscribe = (() => void) | undefined;

export const useDataSubscriber = <T, R>(
  fetcher: (
    handleData: (data: T | null) => void,
    variables?: DataSubscriberOptions<R>['variables'],
  ) => OnSnapshotUnsubscribe,
  opts?: DataSubscriberOptions<R>,
) => {
  // Should probably have familyId in this
  const cacheKey = useMemo(() => buildCacheKey(fetcher, opts), [fetcher, opts]);
  const cachedData = useMemo(
    () => (cacheKey ? (cache[cacheKey] as T | null) : null),
    [cacheKey],
  );
  const [loading, setLoading] = useState(!opts?.skip);
  const [data, setData] = useState<T | null>(cachedData);

  const handleData = useCallback(
    (newData: T | null) => {
      if (cacheKey) {
        cache[cacheKey] = newData;
      }

      setData(newData);
      setLoading(false);
    },
    [cacheKey],
  );

  useEffect(() => {
    if (opts?.skip) {
      setLoading(false);
      return;
    }

    setLoading(true);

    const unsubscribe = fetcher(handleData, opts?.variables);

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [fetcher, opts, handleData]);

  return useMemo(
    () => ({ data: data || cachedData, loading: loading && !data }),
    [cachedData, data, loading],
  );
};
