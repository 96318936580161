import { useCallback, useMemo, useState } from 'react';
import ListItem from '../ui/list/listItem';
import ListContainer, { EMBEDDED } from '../ui/list/listContainer';
import ListSearch from '../ui/list/listSearch';
import Plus from '../icons/plus';
import CreateSideModal from '../sides/createSideModal';
import Confirm from '../icons/confirm';
import LoadingSpinner from '../ui/loadingSpinner';
import { Side } from '../sides/types';
import { useListSides } from '../sides/hooks/useListSides';

type SidePickerProps = {
  sideIds: Array<string>;
  sides: Array<Side>;
  onToggleItem: (sideId: string) => void;
};

const MODE_CREATE = 'CREATE';
const MODE_PICK = 'PICK';

const SidePicker = ({ sideIds, sides, onToggleItem }: SidePickerProps) => {
  const [mode, setMode] = useState(MODE_PICK);

  const handleToggleItem = useCallback(
    (sideId: string) => {
      onToggleItem(sideId);
      setMode(MODE_PICK);
    },
    [onToggleItem],
  );

  const [query, setQuery] = useState('');
  const handleSearch = useCallback((q: string) => {
    setQuery(q);
  }, []);

  const sortedSides = useMemo(() => {
    const reQuery = new RegExp(query, 'i');

    return sides
      .filter((side) => reQuery.test(side.name))
      .sort((sideA, sideB) => sideA.name.localeCompare(sideB.name));
  }, [sides, query]);

  const handleCancelCreate = useCallback(() => {
    setMode(MODE_PICK);
    setQuery('');
  }, []);

  const sideIdSet = useMemo(() => new Set(sideIds), [sideIds]);

  return mode === MODE_PICK ? (
    <ListContainer use={EMBEDDED}>
      <ListSearch onSearch={handleSearch} autofocus={true} />
      {query && (
        <ListItem onClick={() => setMode(MODE_CREATE)}>
          <div className="flex flex-row items-center">
            <div className="bg-ron rounded-full mr-2 w-button-sm h-button-sm">
              <Plus className="stroke-white stroke-[3px]" />
            </div>
            <div>Create "{query}"</div>
          </div>
        </ListItem>
      )}
      {sortedSides.map((side) => (
        <ListItem key={side.id} onClick={() => handleToggleItem(side.id)}>
          <div className="flex flex-row items-center">
            <div className="w-button-sm h-button-sm mr-2">
              <Confirm
                color={
                  sideIdSet.has(side.id) ? 'stroke-ron' : 'stroke-slate-200'
                }
              />
            </div>
            <div>{side.name}</div>
          </div>
        </ListItem>
      ))}
    </ListContainer>
  ) : (
    <CreateSideModal
      defaultName={query}
      onCancel={handleCancelCreate}
      onClose={(sideId) => handleToggleItem(sideId)}
    />
  );
};

const SidePickerLoader = (props: Omit<SidePickerProps, 'sides'>) => {
  const { data: sides, loading } = useListSides();

  if (loading) {
    return <LoadingSpinner />;
  }

  return <SidePicker sides={sides || []} {...props} />;
};

export default SidePickerLoader;
