import { useCallback, useMemo } from 'react';
import { OnSnapshotCallback, useDataSubscriber } from '../../lib/useDataLoader';
import { useAuth } from '../../auth/useAuth';
import { getSidesById } from '../api/getSidesById';
import { Side } from '../types';

export type UseGetSidesByIdVariables = { ids: Array<string> } | undefined;

export const useGetSidesById = (ids: Array<string>) => {
  const { familyId } = useAuth();

  const fetcher = useCallback(
    (
      handleData: OnSnapshotCallback<Record<string, Side>>,
      variables: UseGetSidesByIdVariables,
    ) => {
      if (!familyId || !variables?.ids?.length) {
        handleData(null);
        return;
      }

      return getSidesById(familyId, variables?.ids, handleData);
    },
    [familyId],
  );

  const opts = useMemo(() => ({ cache: true, variables: { ids } }), [ids]);

  return useDataSubscriber(fetcher, opts);
};
