import { doc, updateDoc } from 'firebase/firestore';
import { omit } from '../../lib/omit';
import { Menu } from '../types';
import { db } from './db';

export const updateMenu = async (familyId: string, menu: Menu) => {
  try {
    return updateDoc(doc(db(familyId), menu.id), omit(['id', 'mealId'], menu));
  } catch (e) {
    console.error('Error updating document', e);
  }

  throw new Error('Could not update');
};
