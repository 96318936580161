import {
  FRIDAY,
  MenuEgg,
  MenuItem,
  MONDAY,
  SATURDAY,
  SUNDAY,
  THURSDAY,
  TUESDAY,
  WEDNESDAY,
} from '../types';

const emptyMenuItem = (): MenuItem => ({});

export const emptyMenu = (week: string): MenuEgg => ({
  week,
  days: {
    [SUNDAY]: emptyMenuItem(),
    [MONDAY]: emptyMenuItem(),
    [TUESDAY]: emptyMenuItem(),
    [WEDNESDAY]: emptyMenuItem(),
    [THURSDAY]: emptyMenuItem(),
    [FRIDAY]: emptyMenuItem(),
    [SATURDAY]: emptyMenuItem(),
  },
});
