type ProfilePropsType = { className?: string };

const Profile = ({
  className = 'stroke-[3px] stroke-black',
}: ProfilePropsType) => (
  <svg viewBox="0 0 409.165 409.164">
    <g>
      <g>
        <path
          className={`${className}`}
          d="M204.583,216.671c50.664,0,91.74-48.075,91.74-107.378c0-82.237-41.074-107.377-91.74-107.377
			c-50.668,0-91.74,25.14-91.74,107.377C112.844,168.596,153.916,216.671,204.583,216.671z"
        />
        <path
          className={`${className}`}
          d="M407.164,374.717L360.88,270.454c-2.117-4.771-5.836-8.728-10.465-11.138l-71.83-37.392
			c-1.584-0.823-3.502-0.663-4.926,0.415c-20.316,15.366-44.203,23.488-69.076,23.488c-24.877,0-48.762-8.122-69.078-23.488
			c-1.428-1.078-3.346-1.238-4.93-0.415L58.75,259.316c-4.631,2.41-8.346,6.365-10.465,11.138L2.001,374.717
			c-3.191,7.188-2.537,15.412,1.75,22.005c4.285,6.592,11.537,10.526,19.4,10.526h362.861c7.863,0,15.117-3.936,19.402-10.527
			C409.699,390.129,410.355,381.902,407.164,374.717z"
        />
      </g>
    </g>
  </svg>
);

export default Profile;
