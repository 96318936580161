import { useCallback, useRef, useState } from 'react';
import FlyOutMenuItem from '../ui/flyOutMenu/flyOutMenuItem';
import FlyOutMenu from '../ui/flyOutMenu/flyOutMenu';
import ThreeDots from '../icons/threeDots';
import { MenuItem } from './types';
import { Meal } from '../meals/types';

type MealContextMenuPropsType = {
  meal?: Meal;
  menuItem: MenuItem;
  onClear: () => void;
  onGoToRecipe: () => void;
};

const MealContextMenu = ({
  meal,
  menuItem,
  onClear,
  onGoToRecipe,
}: MealContextMenuPropsType) => {
  const menuRef = useRef<HTMLButtonElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleClick = useCallback((e: React.SyntheticEvent) => {
    e.stopPropagation();
    setMenuOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setMenuOpen(false);
  }, []);

  if (!meal) {
    return null;
  }

  return (
    <>
      <button ref={menuRef} onClick={handleClick} className="w-button h-button">
        <ThreeDots />
      </button>
      {menuOpen && (
        <FlyOutMenu onClose={handleClose} tetherRef={menuRef}>
          {menuItem.dinnerIds?.length && (
            <FlyOutMenuItem onClick={onClear}>Clear</FlyOutMenuItem>
          )}
          {!!meal?.url?.length && (
            <FlyOutMenuItem onClick={onGoToRecipe}>Go to recipe</FlyOutMenuItem>
          )}
        </FlyOutMenu>
      )}
    </>
  );
};

export default MealContextMenu;
