import { onSnapshot, query, where } from 'firebase/firestore';
import { OnSnapshotCallback } from '../../lib/useDataLoader';
import { emptyMenu } from '../lib/emptyMenu';
import { Menu, MenuEgg } from '../types';
import { db } from './db';

const migrateMealId = (week: MenuEgg) => {
  Object.entries(week.days).forEach(([__dayOfWeek, menuItem]) => {
    if (!menuItem.dinnerIds && menuItem.mealId) {
      menuItem.dinnerIds = [menuItem.mealId];
    }
  });

  return week;
};

export const getWeeksMenu = (
  familyId: string,
  week: string,
  onMenu: OnSnapshotCallback<Menu | MenuEgg>,
) => {
  const q = query(db(familyId), where('week', '==', week));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    if (querySnapshot.empty) {
      onMenu(emptyMenu(week));
      return;
    }

    const docSnapshot = querySnapshot.docs[0];
    onMenu(
      migrateMealId({
        id: docSnapshot.id,
        ...docSnapshot.data(),
      } as Menu),
    );
  });

  return () => {
    unsubscribe();
  };
};
