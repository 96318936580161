type PlusPropsType = { className?: string };

const Plus = ({
  className = 'stroke-[3px] stroke-gryffindor',
}: PlusPropsType) => (
  <svg viewBox="0 0 40 40">
    <path className={className} d="M 20,10 L 20,30 M 10,20 L 30,20" />
  </svg>
);

export default Plus;
