import { doc, runTransaction } from 'firebase/firestore';
import { getDb } from '../firebase';

export const FAMILY_VANITY_ID = 'family:vanityIds';

type GlobalIdType = typeof FAMILY_VANITY_ID;

export const getAndInc = async (globalId: GlobalIdType) => {
  const globalIdRef = doc(getDb(), 'globalIds', globalId);

  return runTransaction(getDb(), async (transaction) => {
    const globalIdDoc = await transaction.get(globalIdRef);

    const nextGlobalId = globalIdDoc?.data()?.next;
    transaction.update(globalIdRef, { next: nextGlobalId + 1 });

    return nextGlobalId;
  });
};
