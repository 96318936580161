import { useCallback } from 'react';
import { useAuth } from '../../auth';
import { getAllMeals } from '../api/getAllMeals';

export const useGetAllMeals = () => {
  const { familyId } = useAuth();

  const fetcher = useCallback(() => {
    if (!familyId) {
      return Promise.resolve([]);
    }

    return getAllMeals(familyId);
  }, [familyId]);

  return fetcher;
};
