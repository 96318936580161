import { doc, updateDoc } from 'firebase/firestore';
import { omit } from '../../lib/omit';
import { Meal } from '../types';
import { db } from './db';

export const updateMeal = async (familyId: string, meal: Meal) => {
  try {
    return updateDoc(doc(db(familyId), meal.id), omit(['id'], meal));
  } catch (e) {
    console.error('Error updating document', e);
  }

  throw new Error('Could not update');
};
