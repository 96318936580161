import { onSnapshot, query } from 'firebase/firestore';
import { OnSnapshotCallback } from '../../lib/useDataLoader';
import { Side } from '../types';
import { db } from './db';

export const listSides = (
  familyId: string,
  onSides: OnSnapshotCallback<Array<Side>>,
) => {
  const q = query(db(familyId));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    onSides(
      querySnapshot.docs.map((d) => ({
        id: d.id,
        ...d.data(),
      })) as Side[],
    );
  });

  return () => {
    unsubscribe();
  };
};
