import { useCallback, useEffect } from 'react';
import {
  ERROR,
  useNotifications,
} from '../../notifications/NotificationProvider';

const GlobalErrorHandler = () => {
  const { addNotification } = useNotifications();

  const handleError = useCallback(() => {
    addNotification({
      level: ERROR,
      message: 'Sorry, something went wrong. Please try again.',
    });
  }, [addNotification]);

  const handleRejection = useCallback(() => {
    addNotification({
      level: ERROR,
      message: 'Sorry, something went wrong. Please try again.',
    });
  }, [addNotification]);

  useEffect(() => {
    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handleRejection);

    return () => {
      window.removeEventListener('error', handleError);
      window.removeEventListener('unhandledrejection', handleRejection);
    };
  }, [handleError, handleRejection]);

  return null;
};

export default GlobalErrorHandler;
