import { Routes, Route } from 'react-router-dom';
import Recipe from './recipe';

const RecipesPage = () => {
  return (
    <Routes>
      <Route path="/" element={<Recipe />} />
    </Routes>
  );
};

export default RecipesPage;
